import React, { useState } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Label, Input } from "reactstrap";

import { useTranslation } from "react-i18next";
import { fetchApi } from "./utils/api";

function ScreenForgotPassword() {
  const { t, i18n } = useTranslation();
  const [signInEmail, setSignInEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccesMessage] = useState("");

  const handleChangePassword = async () => {
    console.log(signInEmail);
    const result = await fetchApi(
      `/users/forgotpassword?email=${signInEmail}&locale=${i18n.language}`,
      "GET"
    );

    if (result === "success") {
      setErrorMessage("");
      setSuccesMessage("Un email vous a été envoyé");
    }
    if (result === "error") {
      setSuccesMessage("");
      setErrorMessage("Échec lors de l'envoi de l'email");
    }
  };
  return (
    <div className="full-width bg-inscription">
      <Header />
      <div className="full-width">
        <div style={{ marginBottom: "6.64%" }}>
          <h1 className="titre-h1-page">Mot de passe oublié</h1>
          <div className="formulaire-default">
            <Label className="formulaire-label" for="Prenom Benevole">
              {t("mail.label")}
            </Label>
            <Input
              value={signInEmail}
              onChange={(e) => setSignInEmail(e.target.value)}
              type="email"
              name="email"
              id="emailB"
              placeholder={t("Enter your email address.label")}
              bsSize="sm"
            />
          </div>
          <div className="formulaire-default">
            {errorMessage ? (
              <span style={{ color: "red" }}>{errorMessage}</span>
            ) : null}
            {successMessage ? (
              <span style={{ color: "green" }}>{successMessage}</span>
            ) : null}
          </div>
          <span
            onClick={() => handleChangePassword()}
            className="button-default-small"
            style={{
              marginLeft: "10%",
              marginTop: "40px !important",
              cursor: "pointer",
            }}
          >
            Envoyer
          </span>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default ScreenForgotPassword;
