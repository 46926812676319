import React, { useState, useEffect } from "react";
import {
  Label,
  Input,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import "./App.css";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import HeaderConnected from "./components/HeaderConnected";
import Footer from "./components/Footer";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { fetchApi } from "./utils/api";

const ScreenInscriptionOeuvre = (props) => {
  const { t, i18n } = useTranslation();
  const [signUpName, setSignUpName] = useState("");
  const [signUpFirstName, setSignUpFirstName] = useState("");
  const [signUpEntiteName, setSignUpEntiteName] = useState("");
  const [signUpApe, setSignUpApe] = useState("");
  const [signUpVille, setSignUpVille] = useState("");
  const [signUpDesc, setSignUpDesc] = useState("");
  const [signupCountry, setSignUpCountry] = useState("");
  const [validatedInscription, setValidatedInscription] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalSuccessMessage, setModalSuccessMessage] = useState("");
  const [modalErrorMessage, setModalErrorMessage] = useState("");

  const [error, setError] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();


  const [cookies] = useCookies(["token"]);
  const token = props.userIdentification.token || cookies.token;
  const userType = props.userIdentification.userType || cookies.type;

  useEffect(() => {
    async function loadStatus() {
      const getToken = searchParams.get("callback");
      const response = await fetchApi(`/getCharityStatus?token=${getToken}`);

      if (getToken === null || response.status === "pending") {
        const getType = searchParams.get("type");
        const getUserId = searchParams.get("userId");

        if (getToken && getType && getUserId) {
        fetchApi(`/users/verify-account/${getUserId}/${getToken}/${getType}`)
          .then((res) => {
            setModalVisible(false);
          }).catch((err) => {
            console.log(err);
          });
        } else {
          setModalVisible(true);
        }
      }
    }
    loadStatus();
  }, [token]);

  const handleActivationEmail = async () => {
    const res = await fetchApi(
      `/users/send-activation-email?token=${token}&locale=${i18n.language}`,
      "GET"
    );

    if (res.result === "success") {
      setModalSuccessMessage("L'email vous à été renvoyé");
    }
    if (res.result === "error") {
      setModalErrorMessage("Échec lors de l'envoi du mail");
    }
  };

  const handleSubmitSignupForm = async () => {
    let upload = true;
    if (
      !signupCountry ||
      !signUpName ||
      !signUpDesc ||
      !signUpEntiteName ||
      !signUpFirstName ||
      !signUpVille
    ) {
      upload = false;
      setError("Veuillez remplir tous les champs");
    }
    if (upload) {
      const res = await fetchApi(
        "/users/charity-sign-up-form",
        "POST",
        `nameFromFront=${signUpName}&firstNameFromFront=${signUpFirstName}&entiteFromFront=${signUpEntiteName}&apeFromFront=${signUpApe}&villeFromFront=${signUpVille}&descFromFront=${signUpDesc}&token=${token}&country=${signupCountry}`
      );

      if (res.result === true) {
        setValidatedInscription(true);
      }
    }
  };
  const toggle = () => console.log("do nothing");

  if (validatedInscription) {
    return <Navigate to="/dashboardoeuvre" />;
  }
  // if (!token || userType !== "charity") {
  //   return <Navigate to="/" />;
  // }
  return (
    <div className="full-width bg-inscription-formulaire-asso">
      <HeaderConnected oeuvre show={false} />
      <div
        className="full-width"
        style={{ position: "relative", bottom: "110px" }}
      >
        <>
          <Modal isOpen={modalVisible} toggle={toggle}>
            <ModalBody>
              <div style={{ display: "flex", flexDirection: "column" }}>
                Veuillez cliquer sur le lien reçu par mail pour activer votre
                compte.
                <span style={{ color: "green" }}>
                  {modalSuccessMessage ? modalSuccessMessage : null}
                </span>
                <span style={{ color: "red" }}>
                  {modalErrorMessage ? modalErrorMessage : null}
                </span>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => handleActivationEmail()}>
                Renvoyer le lien
              </Button>
              <Button
                color="secondary"
                onClick={() => window.location.reload()}
              >
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </>
        <div>
          <h1 className="titre-h1-page">{t("registration.label")} </h1>
          <div className="full-width">
            <div className="formulaire-default">
              <Label for="Nom Benevole" className="formulaire-label">
                {" "}
                {t("firstname.label")}{" "}
              </Label>
              <Input
                onChange={(e) => setSignUpFirstName(e.target.value)}
                type="lastname"
                name="lastname"
                id="lastnameB"
                placeholder="Jacques"
                bsSize="sm"
              />
            </div>

            <div className="formulaire-default">
              <Label for="Prenom Benevole" className="formulaire-label">
                {" "}
                {t("name.label")}{" "}
              </Label>
              <Input
                onChange={(e) => setSignUpName(e.target.value)}
                type="firstname"
                name="firstname"
                id="firstnameB"
                placeholder="Grandjean"
                bsSize="sm"
              />
            </div>

            <div className="formulaire-default">
              <Label for="entite" className="formulaire-label">
                {t("charity name.label")}{" "}
              </Label>
              <Input
                onChange={(e) => setSignUpEntiteName(e.target.value)}
                type="entite"
                name="entite"
                id="entiteA"
                placeholder="Association B"
                bsSize="sm"
              />
            </div>

            <div className="formulaire-default">
              <Label for="ape" className="formulaire-label facultatif">
                {t("APE code.label")}{" "}
              </Label>
              <Input
                onChange={(e) => setSignUpApe(e.target.value)}
                type="ape"
                name="ape"
                id="ape"
                placeholder={t("Please enter your APE code")}
                bsSize="sm"
              />
            </div>

            <div className="formulaire-default">
              <Label for="ville" className="formulaire-label">
                {t("city.label")}{" "}
              </Label>
              <Input
                onChange={(e) => setSignUpVille(e.target.value)}
                type="ville"
                name="ville"
                id="ville"
                placeholder="Paris"
                bsSize="sm"
              />
            </div>
            <div className="formulaire-default">
              <Label for="country" className="formulaire-label">
                Pays{" "}
              </Label>
              <Input
                onChange={(e) => setSignUpCountry(e.target.value)}
                value={signupCountry}
                type="country"
                name="country"
                id="country"
                placeholder="France"
                bsSize="sm"
              />
            </div>

            <div className="formulaire-default">
              <Label for="exampleText" className="formulaire-label facultatif">
                {t("your description.label")}
              </Label>
              <Input
                onChange={(e) => setSignUpDesc(e.target.value)}
                type="textarea"
                name="text"
                id="experience-pro"
                placeholder={t(
                  "Please write a few words about your association.label"
                )}
                bsSize="sm"
              />
            </div>
          </div>
          <Button
            onClick={() => handleSubmitSignupForm()}
            className="button-default-small"
            style={{ marginLeft: "10%", marginTop: "40px !important" }}
          >
            S'INSCRIRE
          </Button>
        </div>
        <Footer />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { userIdentification: state.token };
}

export default connect(mapStateToProps)(ScreenInscriptionOeuvre);
