import React from "react";

const CardBenevoles = (props) => {
  let skillList = props.skills.map((skill, i) => {
    return <p className="competences-liste">{skill.value}</p>;
  });

  return (
    <div>
      <div className="card-type-2" style={{width: "100%", marginBottom: 20}}>
        <div className="wrapper">
          <div>
            <img
              className="card-image"
              src="/images/detailprojet.jpg"
              alt="a generic background"
              style={{ width: "215px", borderRadius: "10px" }}
            />
          </div>
          <div>
            <p className="titre-card-type-2">{props.title}</p>
            <p className="sous-titre-card-type-2">Compétences requises :</p>
            {skillList}
          </div>
        </div>
        <div style={{ display: "flex" }} className="cardbox-project-bank desc">
          <p className="text-card">{props.desc}</p>
        </div>
      </div>
    </div>
  );
};

export default CardBenevoles;
