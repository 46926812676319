import React, { useEffect, useState } from "react";
import "./App.css";
import { Navigate } from "react-router-dom";
import Footer from "./components/Footer";
import CardProjectBank from "./components/CardProjectBank";
import HeaderConnected from "./components/HeaderConnected";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { fetchApi } from "./utils/api";

const ScreenProjectBank = ({ userIdentification }) => {
  const { t } = useTranslation();
  const [projectList, setProjectList] = useState([]);
  const [cookies] = useCookies(["token"]);

  const tokenId = userIdentification.token || cookies.token;
  const userType = userIdentification.userType || cookies.type;

  useEffect(() => {
    const findProjects = async () => {
      const res = await fetchApi(`/preview-project-bank`, "GET");

      console.log(res);
      setProjectList(res.projectList);
    };
    findProjects();
  }, []);

  let projectBankList = (
    <div style={{ marginLeft: "15%" }}>
      <p className="titre-card-type-2 no-mp">
        {t("no project for the moment.label")}
      </p>
    </div>
  );

  if (projectList.length > 0) {
    projectBankList = projectList.map((project, i) => {
      let projectSrc = "/images/home-bene/projet3.png";
      if (project.category === "Environnement") {
        projectSrc = "/images/projectPic/environnemental.jpg";
      }
      if (project.category === "Solidarité") {
        projectSrc = "/images/projectPic/solidarity.jpg";
      }
      if (project.category === "Pauvreté") {
        projectSrc = "/images/projectPic/poverty.jpg";
      }
      return (
        <CardProjectBank
          key={i}
          title={project.title}
          src={projectSrc}
          desc={project.desc}
          skills={project.competence}
          projectToken={project.projectToken}
        />
      );
    });
  }

  if (!tokenId || userType !== "volunteer") {
    return <Navigate to="/" />;
  }
  return (
    <div className="full-width bg-skill-bank">
      <HeaderConnected show={false} />
      <div
        style={{ position: "relative", bottom: "110px" }}
        className="full-width"
      >
        <h2 className="titre-h1-page">{t("project bank.label")} </h2>

        {projectBankList}
      </div>
      <Footer />
    </div>
  );
};

function mapStateToProps(state) {
  return { userIdentification: state.token };
}

export default connect(mapStateToProps, null)(ScreenProjectBank);
