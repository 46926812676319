import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import HeaderConnected from "./components/HeaderConnected";
import Footer from "./components/Footer";
import "./App.css";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { fetchApi } from "./utils/api";

function ScreenProfilView({ userIdentification }) {
  const [cookies] = useCookies(["token"]);
  const { t } = useTranslation();
  const { userToken } = useParams();
  const [profilData, setProfilData] = useState();
  const [skills, setSkills] = useState([]);

  const tokenId = userIdentification.token || cookies.token;
  const userType = userIdentification.userType || cookies.type;

  useEffect(() => {
    const loadProfilData = async () => {
      const res = await fetchApi(
        "/getprofildata",
        "POST",
        `token=${userToken}`
      );

      setProfilData(res);
      setSkills(res.competences);
    };
    if (userToken) {
      loadProfilData();
    }
  }, [userToken]);

  if (!tokenId || userType !== "charity") {
    return <Navigate to="/" />;
  }
  return (
    <div className="full-width bg-profil">
      <HeaderConnected show={false} oeuvre />
      <div className="full-width profil_container">
        <h1 className="titre-h1-page">Profil</h1>
        <div>
          <div className="card-dashboard">
            <div>
              <img
                className="image-dashboard-profil"
                src="/images/skill-bank/panda-bear.png"
                alt="a generic background"
              />
            </div>
            <div className="dashboard-content" style={{ alignItems: "center" }}>
              <p className="titre-card-type-2 no-mp">
                {profilData?.prenom} {profilData?.nom}
              </p>
            </div>
          </div>
          <div className="card-dashboard">
            <p className="text-dashboard-profil">{profilData?.desc}</p>
          </div>
        </div>
        <div>
          <h2 className="titre-h2-page rmv-mb">{t("skills.label")}</h2>
        </div>
        {skills.map((skill, i) => {
          return (
            <div style={{ display: "flex" }}>
              <div className="liste-de-competence-profil">
                <p className="text-competence-profil">{skill.label}</p>
              </div>
            </div>
          );
        })}

        <h2 className="titre-h2-page rmv-mb">{t("beliefs.label")}</h2>
        {/* <Link className='button-default-small-purple' style={{marginLeft:''}}>EDITER</Link> */}
        <div className="card-dashboard">
          <p className="text-dashboard-profil">{profilData?.convictions}</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
function mapStateToProps(state) {
  return { userIdentification: state.token };
}

export default connect(mapStateToProps, null)(ScreenProfilView);
