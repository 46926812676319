import React, { useState } from "react";
import { Label, Input, Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import "./App.css";
import { Link, useNavigate } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

import { fetchApi } from "./utils/api";

const ScreenFormulaireContact = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [message, setMessage] = useState("");
  const [modalVisible, setModalVisible] = useState(false);

  const [listErrorsSignin, setErrorsSignin] = useState([]);

  const [, setCookie] = useCookies(["token"]);

  var handleSubmitSignin = async () => {
    let upload = true;
    if (email === "" || firstname === "" || lastname === "" ||message === "") {
      setErrorsSignin([t("pleasefill.label")]);
      upload = false;
    }

    if (upload) {
      const res = await fetchApi(
        "/contactform",
        "POST",
        `email=${email}&firstname=${firstname}&lastname=${lastname}&message=${message}`
      ).then((res) => {
        setModalVisible(true);
      });
    }
  };

  var tabErrorsSignin = listErrorsSignin.map((error, i) => {
    return <p style={{ marginLeft: "10%", color: "#DC3545" }}>{error}</p>;
  });

  return (
    <div className="full-width bg-inscription">
      <Modal isOpen={modalVisible} toggle={() => setModalVisible(!modalVisible)}>
        <ModalBody>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {t("form.validated.label")}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => setModalVisible(false)}
          >
            OK
          </Button>
        </ModalFooter>
      </Modal>
      <Header />
      <div className="full-width">
        <div style={{ marginBottom: "6.64%" }}>
          <h1 className="titre-h1-page">{t("contact.label")}</h1>
          <div className="formulaire-default">
            <Label className="formulaire-label" for="Prenom Benevole">
              {t("mail.label")}
            </Label>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              name="email"
              id="emailB"
              placeholder={t("Enter your email address.label")}
              bsSize="sm"
            />
          </div>
          <div className="formulaire-default">
            <Label className="formulaire-label" for="Prenom Benevole">
              {t("firstname.label")}
            </Label>
            <Input
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
              type="text"
              name="firstname"
              id="emailB"
              placeholder={t("firstname.label")}
              bsSize="sm"
            />
          </div>
          <div className="formulaire-default">
            <Label className="formulaire-label" for="Prenom Benevole">
              {t("name.label")}
            </Label>
            <Input
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              type="text"
              name="name"
              id="emailB"
              placeholder={t("name.label")}
              bsSize="sm"
            />
          </div>
          <div className="formulaire-default">
            <Label className="formulaire-label" for="Prenom Benevole">
              {t("message.label")}
            </Label>
            <Input
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              type="textarea"
              name="message"
              id="emailB"
              placeholder={t("message.label")}
              bsSize="sm"
            />
          </div>
          {tabErrorsSignin}
          <span
            onClick={() => handleSubmitSignin()}
            className="button-default-small"
            style={{
              marginLeft: "10%",
              marginTop: "40px !important",
              cursor: "pointer",
            }}
          >
            {t("validate.label")}
          </span>
        </div>
        <Footer />
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    addToken: function (userIdentification) {
      dispatch({ type: "addToken", userIdentification });
    },
  };
}

export default connect(null, mapDispatchToProps)(ScreenFormulaireContact);
