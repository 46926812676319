import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import { useCookies } from "react-cookie";

function HeaderConnected({ show, oeuvre }) {
  const [, removeCookie] = useCookies(["token"]);
  const [showMenu, setShowMenu] = useState(show);

  const handleRemoveCookie = () => {
    removeCookie("token");
    removeCookie("type");
  };
  if (oeuvre) {
    return (
      <>
        <div className="navigation">
          <Link to="/" className="logo">
            ACTOMOBILE
          </Link>
          <div style={{ marginLeft: "auto" }}>
            <div onClick={() => setShowMenu(!showMenu)} className="nav-item">
              <FontAwesomeIcon
                icon={faBars}
                style={{
                  color: "#55C2B5",
                  marginRight: "5px",
                  marginBottom: "0px",
                }}
              />
            </div>
          </div>
        </div>
        <div className={`hamburger_hidden ${showMenu && "hamburger_show"}`}>
          <Link to="/dashboardoeuvre" className="hamburger_item">
            <span className="hamburger_text">Tableau de bord</span>
          </Link>
          <Link to="/skillbank" className="hamburger_item">
            <span className="hamburger_text">Banque de compétences</span>
          </Link>
          <Link to="/creationprojet" className="hamburger_item">
            <span className="hamburger_text">Créer un projet</span>
          </Link>
          <Link
            onClick={() => handleRemoveCookie()}
            to="/"
            className="hamburger_item"
          >
            <span className="hamburger_text">Deconnexion</span>
          </Link>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="navigation">
        <Link to="/" className="logo">
          ACTOMOBILE
        </Link>
        <div style={{ marginLeft: "auto" }}>
          <div onClick={() => setShowMenu(!showMenu)} className="nav-item">
            <FontAwesomeIcon
              icon={faBars}
              style={{
                color: "#55C2B5",
                marginRight: "5px",
                marginBottom: "0px",
              }}
            />
          </div>
        </div>
      </div>
      <div className={`hamburger_hidden ${showMenu && "hamburger_show"}`}>
        <Link to="/dashboardbenevoles" className="hamburger_item">
          <span className="hamburger_text">Tableau de bord</span>
        </Link>
        <Link to="/projectbank" className="hamburger_item">
          <span className="hamburger_text">Banque de projet</span>
        </Link>
        <Link to="/profilbenevole" className="hamburger_item">
          <span className="hamburger_text">Mon profil</span>
        </Link>
        <Link
          onClick={() => handleRemoveCookie()}
          to="/"
          className="hamburger_item"
        >
          <span className="hamburger_text">Deconnexion</span>
        </Link>
      </div>
    </>
  );
}

export default HeaderConnected;
