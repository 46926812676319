import React from "react";
import { Row, Col } from "reactstrap";
import "../App.css";
import { useTranslation } from "react-i18next";
import headerCharite from "../images/header-asso.jpg";

const Charite = () => {
  const { t } = useTranslation();

  return (
    <div className="full-width">
      <Row>
        <Col xs="12">
          <div className="opacity-20-dark">
            <img
              className="image-header ratio-ok"
              src={headerCharite}
              alt="A group of volunteers"
            />
            <div className="titre-image">
              <h1 className="titre-h1-bis">{t("charity.label")}</h1>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Charite;
