import React from "react";
import "./App.css";
import { Link, Navigate } from "react-router-dom";
import HeaderConnected from "./components/HeaderConnected";
import Footer from "./components/Footer";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

const ScreenProjetAssoDetail = (props) => {
  const { t } = useTranslation();
  const [cookies] = useCookies(["token"]);

  const token = props.userIdentification.token || cookies.token;
  const userType = props.userIdentification.userType || cookies.type;

  let tableauCompetences;
  if (props.value.competence) {
    tableauCompetences = props.value.competence.map((skill, i) => {
      return <p className="texte-competences-liste">{skill.value}</p>;
    });
  }

  if (!props.value) {
    return <Navigate to="/" />;
  }
  console.log(token, userType, cookies);
  if (!token || userType !== "charity") {
    return <Navigate to="/" />;
  }
  return (
    <div className="full-width bg-projet-detail-asso">
      <HeaderConnected show={false} oeuvre />
      <div
        className="full-width"
        style={{ position: "relative", bottom: "110px" }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            className="image-projet-detail"
            src="/images/detailprojet.jpg"
            alt="a generic background"
          />

          <div>
            <h1 className="titre-h1-page">{props.value.title}</h1>
            <p className="text-projet-detail">
              {t("This fabulous project will begin on.label")}{" "}
              {new Date(props.value.startDate).toLocaleDateString()}
            </p>
            <p className="text-projet-detail">
              {t("it will finish on.label")}{" "}
              {new Date(props.value.endDate).toLocaleDateString()}
            </p>
            {/* <p className='text-projet-detail'>Association description</p> */}
          </div>
        </div>

        <div style={{ marginTop: "40px" }}>
          <div className="card-projet-detail" style={{ display: "flex" }}>
            <div>
              <p className="titre-compétences">{t("require skills.label")}</p>
              {tableauCompetences}
            </div>
          </div>
        </div>
        <div style={{ marginTop: "50px" }}>
          <div className="card-projet-detail">
            <p className="titre-compétences">{t("description.label")}</p>
            <p className="text-dashboard">{props.value.desc}</p>
          </div>
        </div>
        <div
          style={{
            marginTop: "60px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Link className="button-default" to="/dashboardoeuvre">
            OK
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

function mapStateToProps(state) {
  return { value: state.dataProject, userIdentification: state.token };
}

export default connect(mapStateToProps, null)(ScreenProjetAssoDetail);
