import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

const Header = () => {
  const { t } = useTranslation();
  const [{token, type},,] = useCookies(["token"]);
  console.log(token, type);
  return (
    <div>
      <div className="navigation">
        <Link to="/" className="logo">
          ACTOMOBILE
        </Link>
        <div style={{ marginLeft: "auto" }}>
          <Link to="/apropos" className="about-us-link">
            <p className="nav-item">{t("about.label")}</p>
          </Link>
          {token && token !== "undefined" ? <Link to={type === "volunteer" ? "/dashboardbenevoles" : "/dashboardoeuvre"} className="nav-item">
            {t("mydashboard.label")}
          </Link> : null}
          {!token || token === "undefined" ? <>
            <Link to="/connectionbenevoles" className="nav-item">
              <FontAwesomeIcon
                icon={faUser}
                style={{
                  color: "#55C2B5",
                  marginRight: "5px",
                  marginBottom: "0px",
                }}
              />
              {t("connect.label")}
            </Link>
            <Link to="/inscriptionbenevoles" className="nav-item">
              <FontAwesomeIcon
                icon={faUserPlus}
                style={{
                  color: "#55C2B5",
                  marginRight: "5px",
                  marginBottom: "0px",
                }}
              />
              {t("register.label")}
            </Link>
          </> : null}
        </div>
      </div>
    </div>
  );
};

export default Header;
