import React, { useState } from "react";
import { Label, Input } from "reactstrap";
import "./App.css";
import { Navigate } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Select from "react-select";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { fetchApi } from "./utils/api";
import { Button } from "reactstrap";

const ScreenInscriptionBenevoles = (props) => {
  const [rememberMe, setRememberMe] = useState(true);
  const [, setCookie] = useCookies(["token"]);
  const { t, i18n } = useTranslation();
  const dataSelect = [
    {
      value: 1,
      label: `${t("volunteer.label")}`,
    },
    {
      value: 2,
      label: `${t("charity.label")}`,
    },
  ];

  const [signUpEmail, setSignUpEmail] = useState("");
  const [signUpPassword, setSignUpPassword] = useState("");
  const [signUpType, setSignUpType] = useState();

  const [assoExists, setAssoExists] = useState(false);
  const [benevoleExists, setBenevoleExists] = useState(false);

  const [listErrorsSignup, setErrorsSignup] = useState([]);

  var handleSubmitSignup = async () => {
    let upload = true;
    if (!signUpType || signUpPassword === "" || signUpEmail === "") {
      upload = false;
      setErrorsSignup(["Veuillez remplir tous les champs"]);
    }

    if (upload) {
      const res = await fetchApi(
        "/users/sign-up?locale=" + i18n.language,
        "POST",
        `emailFromFront=${signUpEmail}&passwordFromFront=${signUpPassword}&typeFromFront=${signUpType.value}`
      );

      if (res.result === true && res.type === "Oeuvres caritatives") {
        if (rememberMe) {
          setCookie("token", res.token, { path: "/" });
          setCookie("type", "charity", { path: "/" });
        }
        props.addToken({ token: res.token, userType: "charity" });
        setAssoExists(true);
      } else {
        setErrorsSignup(res.error);
      }

      if (res.result === true && res.type === "Bénévoles") {
        if (rememberMe) {
          setCookie("token", res.token, { path: "/" });
          setCookie("type", "volunteer", { path: "/" });
        }
        props.addToken({ token: res.token, userType: "volunteer" });
        setBenevoleExists(true);
      } else {
        console.log(res);
        setErrorsSignup(res.error);
      }
    }
  };

  const handleChange = (value) => {
    setSignUpType(value);
  };

  if (benevoleExists) {
    return <Navigate to="/inscriptionbenevolesformulaire" />;
  }

  if (assoExists) {
    return <Navigate to="/inscriptionoeuvreformulaire" />;
  }

  var tabErrorsSignup = listErrorsSignup.map((error, i) => {
    return <p style={{ color: "red", marginLeft: 10 }}>{error}</p>;
  });

  return (
    <div className="full-width bg-inscription">
      <Header />
      <div className="full-width">
        <div>
          <h1 className="titre-h1-page">{t("register.label")}</h1>
          <div className="formulaire-default">
            <Label className="formulaire-label" for="Prenom Benevole">
              {t("mail.label")}
            </Label>
            <Input
              onChange={(e) => setSignUpEmail(e.target.value)}
              placeholder={t("Enter your email address.label")}
              bsSize="sm"
            />
          </div>

          <div className="formulaire-default">
            <Label className="formulaire-label" for="mdp">
              {t("password.label")}
            </Label>
            <Input
              onChange={(e) => setSignUpPassword(e.target.value)}
              type="password"
              placeholder={t("Enter your password.label")}
              bsSize="sm"
            />
          </div>

          <div className="formulaire-default">
            <Label for="type" className="formulaire-label">
              {t("profil_type.label")}
            </Label>
            <Select
              placeholder={t("Choose your profile type.label")}
              value={signUpType}
              options={dataSelect}
              onChange={handleChange}
            />

            {tabErrorsSignup}
          </div>
          <div className="formulaire-default">
            <input
              defaultChecked={true}
              onChange={(e) => setRememberMe(e.target.checked)}
              className="checkbox"
              type="checkbox"
            />
            <span>{t("rememberme.label")}</span>
          </div>

          <Button
            onClick={() => handleSubmitSignup()}
            className="button-default-small"
            style={{ marginLeft: "10%", marginTop: "40px !important" }}
          >
            {t("register.label").toUpperCase()}{" "}
          </Button>
        </div>
        <Footer />
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    addToken: function (userIdentification) {
      dispatch({ type: "addToken", userIdentification });
    },
  };
}

export default connect(null, mapDispatchToProps)(ScreenInscriptionBenevoles);
