import React, { useState, useEffect } from "react";
import { Row, Modal, Button, ModalBody, ModalFooter, Input } from "reactstrap";
import "./App.css";
import HeaderConnected from "./components/HeaderConnected";
import Footer from "./components/Footer";
import CardCharite from "./components/CardCharite";
import Select from "react-select";
import { Label } from "reactstrap";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { fetchApi } from "./utils/api";

const ScreenSkillBank = (props) => {
  const { t } = useTranslation();
  const selectCompetence = [
    {
      value: "Agriculture",
      label: t("Agriculture.label"),
    },
    {
      value: "Public speaking",
      label: t("Public speaking.label"),
    },
    {
      value: "Biology",
      label: t("Biology.label"),
    },
    {
      value: "Communication",
      label: t("Communication.label"),
    },
    {
      value: "Construction",
      label: t("Construction.label"),
    },
    {
      value: "UX design",
      label: t("UX design.label"),
    },
    {
      value: "Consulting",
      label: t("Consulting.label"),
    },
    {
      value: "Cloud Computing",
      label: t("Cloud Computing.label"),
    },
    {
      value: "Writing",
      label: t("Writing.label"),
    },
    {
      value: "Education",
      label: t("Education.label"),
    },
    {
      value: "Electrician",
      label: t("Electrician.label"),
    },
    {
      value: "Electronic",
      label: t("Electronic.label"),
    },
    {
      value: "Event",
      label: t("Event.label"),
    },
    {
      value: "Finance",
      label: t("Finance.label"),
    },
    {
      value: "AI (Artificial Intelligence)",
      label: t("AI (Artificial Intelligence).label"),
    },
    {
      value: "Nurse",
      label: t("Nurse.label"),
    },
    {
      value: "Computing",
      label: t("Computing.label"),
    },
    {
      value: "Legal",
      label: t("Legal.label"),
    },
    {
      value: "Masonry",
      label: t("Masonry.label"),
    },
    {
      value: "Marketing",
      label: t("Marketing.label"),
    },
    {
      value: "Mechanic",
      label: t("Mechanic.label"),
    },
    {
      value: "Doctor",
      label: t("Doctor.label"),
    },
    {
      value: "Carpenter",
      label: t("Carpenter.label"),
    },
    {
      value: "Musician",
      label: t("Musician.label"),
    },
    {
      value: "Oceanology",
      label: t("Oceanology.label"),
    },
    {
      value: "Plumbery",
      label: t("Plumbery.label"),
    },
    {
      value: "Video production",
      label: t("Video production.label"),
    },
    {
      value: "School teacher",
      label: t("School teacher.label"),
    },
    {
      value: "Press relation",
      label: t("Press relation.label"),
    },
    {
      value: "Repair",
      label: t("Repair.label"),
    },
    {
      value: "Social Media",
      label: t("Social Media.label"),
    },
    {
      value: "Translation",
      label: t("Translation.label"),
    },
    {
      value: "UX Design",
      label: t("UX Design.label"),
    },
    {
      value: "Veterinarian",
      label: t("Veterinarian.label"),
    },
    {
      value: "Web Analytic",
      label: t("Web Analytic.label"),
    },
    {
      value: "Web design",
      label: t("Web design.label"),
    },
    {
      value: "Web development",
      label: t("Web development.label"),
    },
    {
      value: "Other",
      label: t("Other.label"),
    },
  ];

  const selectDispo = [
    {
      value: "À distance",
      label: `${t("remote.label")}`,
    },
    {
      value: "Locale",
      label: `${t("face-to-face.label")}`,
    },
    {
      value: "Les Deux",
      label: `${t("both.label")}`,
    },
  ];
  const [skillList, setSkillList] = useState([]);
  const [frontList, setFrontlist] = useState([]);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalFailed, setModalFailed] = useState(false);

  const [availabilityFilter, setAvailabilityFilter] = useState("");
  const [skillFilter, setSkillFilter] = useState("");
  const [cityFilter, setCityFilter] = useState("");

  const [cookies] = useCookies(["token"]);

  const token = props.userIdentification.token || cookies.token;
  const userType = props.userIdentification.userType || cookies.type;

  useEffect(() => {
    const findSkills = async () => {
      const res = await fetchApi(`/preview-competence-bank`, "GET");
      setSkillList(res.benevoleList);
      setFrontlist(res.benevoleList);
    };
    findSkills();
  }, []);

  useEffect(() => {
    let tempList = [];

    for (let i = 0; i < skillList.length; i++) {
      console.log(skillList[i].ville);
      if (skillList[i].ville.includes(cityFilter)) {
        tempList.push(skillList[i]);
      }
    }
    setFrontlist(tempList);
  }, [cityFilter, skillList]);

  const handleFilterSkill = (value) => {
    let tempList = [];
    setSkillFilter(value);
    if (availabilityFilter) {
      for (let i = 0; i < skillList.length; i++) {
        for (let j = 0; j < skillList[i].competences.length; j++) {
          if (
            skillList[i].competences[j].value === value &&
            skillList[i].disponibilites === availabilityFilter
          ) {
            tempList.push(skillList[i]);
          }
        }
      }
    } else {
      for (let i = 0; i < skillList.length; i++) {
        for (let j = 0; j < skillList[i].competences.length; j++) {
          if (skillList[i].competences[j].value === value) {
            tempList.push(skillList[i]);
          }
        }
      }
    }
    setFrontlist(tempList);
  };

  const handleFilterAvailability = (value) => {
    let tempList = [];
    setAvailabilityFilter(value);

    if (skillFilter) {
      for (let i = 0; i < skillList.length; i++) {
        for (let j = 0; j < skillList[i].competences.length; j++) {
          if (
            skillList[i].competences[j].value === skillFilter &&
            skillList[i].disponibilites === value
          ) {
            tempList.push(skillList[i]);
          }
        }
      }
    } else {
      for (let i = 0; i < skillList.length; i++) {
        if (skillList[i].disponibilites === value) {
          tempList.push(skillList[i]);
        }
      }
    }
    setFrontlist(tempList);
  };

  const handleSuccess = () => {
    console.log("succes");
    //afficher succes popup
    setModalSuccess(true);
  };

  const handleFailed = () => {
    console.log("failed");
    //afficher failer popup
    setModalFailed(true);
  };

  const toggle = () => setModalSuccess(!modalSuccess);
  const toggleFailed = () => setModalFailed(!modalFailed);

  let skillBankList = (
    <div style={{ position: "relative", right: "250px" }}>
      <p>{t("unfortunately no volunteer has these skills.label")}</p>
    </div>
  );

  if (frontList.length > 0) {
    skillBankList = frontList.map((user, i) => {
      return (
        <CardCharite
          key={i}
          nom={user.nom}
          prenom={user.prenom}
          desc={user.desc}
          skills={user.competences}
          user={user}
          assocToken={token}
          handleSuccess={handleSuccess}
          handleFailed={handleFailed}
        />
      );
    });
  }
  if (!token || userType !== "charity") {
    return <Navigate to="/" />;
  }

  return (
    <div className="full-width bg-skill-bank-page">
      <HeaderConnected oeuvre show={false} />
      <div
        className="full-width"
        style={{ position: "relative", bottom: "110px", overflowY: "scroll" }}
      >
        <>
          <Modal isOpen={modalSuccess} toggle={toggle}>
            <ModalBody>{t("success email.label")}</ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </>
        <>
          <Modal isOpen={modalFailed} toggle={toggleFailed}>
            <ModalBody>{t("failed email.label")}</ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleFailed}>
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </>
        <h2 style={{ marginBottom: 0 }} className="titre-h1-page">
          {t("skills bank.label")}
        </h2>
        <div style={{ marginLeft: "290px" }} className="formulaire-default">
          <Label
            for="exampleSelectMulti"
            className="formulaire-label facultatif"
          >
            {t("search skills.label")}
          </Label>
          <Select
            placeholder={t("please select skills")}
            options={selectCompetence}
            onChange={(e) => handleFilterSkill(e.value)}
          />
        </div>
        <div style={{ marginLeft: "290px" }} className="formulaire-default">
          <Label
            for="exampleSelectMulti"
            className="formulaire-label facultatif"
          >
            {t("search availability.label")}
          </Label>
          <Select
            placeholder={t("please select availability.label")}
            options={selectDispo}
            onChange={(e) => handleFilterAvailability(e.value)}
          />
        </div>
        <div style={{ marginLeft: "290px" }} className="formulaire-default ">
          <Label
            for="exampleSelectMulti"
            className="formulaire-label facultatif"
          >
            Rechercher par ville
          </Label>
          <Input
            onChange={(e) => setCityFilter(e.target.value)}
            value={cityFilter}
            placeholder="city"
          />
        </div>
        <Row className="card-competence">{skillBankList}</Row>
      </div>
      <Footer />
    </div>
  );
};

function mapStateToProps(state) {
  return { userIdentification: state.token };
}

export default connect(mapStateToProps, null)(ScreenSkillBank);
