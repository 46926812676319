import React from "react";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchApi } from "../utils/api";

const CardCharite = (props) => {
  const { t } = useTranslation();
  console.log(props.user);

  let skillList = (
    <p className="competences-liste">Aucune compétences renseignées</p>
  );
  if (props.skills.length > 0) {
    skillList = props.skills.map((skill, i) => {
      return <p className="competences-liste">{skill.value}</p>;
    });
  }

  let image = (
    <div>
      <img
        className="profile-image"
        src="/images/skill-bank/panda-bear.png"
        alt="a generic background"
      />
    </div>
  );

  const handleContact = async (user) => {
    console.log("contact=====>", user);
    const res = await fetchApi(
      "/contact_benevole",
      "POST"`benevoleEmail=${user.email}&associationToken=${props.assocToken}`
    );

    console.log(res.result);
    if (res.result === true) {
      props.handleSuccess();
    } else {
      props.handleFailed();
    }
  };

  return (
    <div>
      <div className="card-type-2">
        <div className="wrapper">
          {image}
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {props.home ? (
                <p className="titre-card-type-2">
                  {props.prenom} {props.nom}
                </p>
              ) : (
                <Link to={`/profil/${props.user.token}`} className="link">
                  {" "}
                  <p className="titre-card-type-2">
                    {props.prenom} {props.nom}
                  </p>
                </Link>
              )}
              {props.home ? null : (
                <div
                  onClick={() => handleContact(props.user)}
                  style={{ cursor: "pointer", marginTop: "25px" }}
                >
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{
                      color: "#55C2B5",
                      marginLeft: "12px",
                      marginBottom: "0px",
                      height: "25px",
                    }}
                  />
                </div>
              )}
            </div>
            <p className="sous-titre-card-type-2">Compétences :</p>
            {skillList}
            <p className="sous-titre-card-type-2">{t("availability.label")}:</p>
            <p className="competences-liste">
              {props.user.disponibilites} {`(${props.user.ville})`}{" "}
            </p>
          </div>
        </div>
        <div style={{ display: "flex" }} className="desc">
          {props.desc !== "undefined" ? (
            <p className="text-card">{props.desc}</p>
          ) : (
            <p className="text-card">Aucune description</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardCharite;
