import React, { useState } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Label, Input } from "reactstrap";
import { Navigate, useParams } from "react-router-dom";
import { fetchApi } from "./utils/api";

function ScreenForgotPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [redirect, setNavigate] = useState(false);
  const { token } = useParams();

  const handleChangePassword = async () => {
    let upload = false;
    if (newPassword === confirmNewPassword) {
      upload = true;
    } else {
      setErrorMessage("Les mots de passes ne correspondent pas");
    }
    if (upload) {
      const res = await fetchApi(
        "/users/change-password",
        "POST",
        `password=${newPassword}&token=${token}`
      );

      if (res === "success") {
        setNavigate(true);
      }
      if (res === "error") {
        setErrorMessage("Erreur. Veuillez re-essayé ultérieurement");
      }
    }
  };

  if (redirect) {
    return <Navigate to="/connectionbenevoles" />;
  }
  return (
    <div className="full-width bg-inscription">
      <Header />
      <div className="full-width">
        <div style={{ marginBottom: "6.64%" }}>
          <h1 className="titre-h1-page">Mot de passe oublié</h1>
          <div className="formulaire-default">
            <Label className="formulaire-label" for="Prenom Benevole">
              Mot de passe
            </Label>
            <Input
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              type="password"
              placeholder="Entrez votre mot de passe"
              bsSize="sm"
            />
          </div>
          <div className="formulaire-default">
            <Label className="formulaire-label" for="Prenom Benevole">
              Confirmez votre mot de passe
            </Label>
            <Input
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              type="password"
              placeholder="Confirmez votre mot de passe"
              bsSize="sm"
            />
          </div>
          <div className="formulaire-default">
            {errorMessage ? (
              <span style={{ color: "red" }}>{errorMessage}</span>
            ) : null}
          </div>
          <span
            onClick={() => handleChangePassword()}
            className="button-default-small"
            style={{
              marginLeft: "10%",
              marginTop: "40px !important",
              cursor: "pointer",
            }}
          >
            Envoyer
          </span>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default ScreenForgotPassword;
