import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Card, CardImg, CardBody, CardTitle, Row } from "reactstrap";
import "./App.css";
import { Link, Navigate } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./i18n";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

const ScreenHome = () => {
  const [cookies] = useCookies(["token"]);
  const { t } = useTranslation();

  return (
    <div className="full-width bg-accueil">
      <Header />
      <div className="opacity-20-dark bg-both-accueil">
        <div className="bg-single-accueil" style={{backgroundImage:"url(/images/accueil/homeasso.jpg)"}}>
          <Link to="/homeoeuvres" className="titre-image-home-asso">
            <h1 className="titre-h1-violet">{t("charity.label")}</h1>
          </Link>
        </div>
        <div className="bg-single-accueil" style={{backgroundImage:"url(/images/accueil/homebene.jpg)"}}>
          <Link to="/homebenevoles" className="titre-image-home-bene">
            <h1 className="titre-h1">{t("volunteer.label")}</h1>
          </Link>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <h3 className="titre-h2"> {t("what'sacto.label")} ?</h3>
      </div>

      <div className="home-main-text">
        <img
          className="ratio-ok"
          src={"/images/accueil/home-cest-quoi.jpg"}
          style={{
            height: "auto",
            width: "13%",
            marginLeft: "20%",
            borderRadius: "10px",
          }}
          alt="background asso"
        />
        <div>
          <p className="text-home-info">
            {t("We all feel more and more concerned.label")}
          </p>
          <p className="text-home-info">{t("Actomobile is a skills bank")} </p>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <h3 className="titre-h2">{t("news.label")}</h3>
      </div>

      <Row className="bg-home-1">
        <Card className="card-actu">
          <CardImg
            top
            width="100%"
            src="/images/accueil/actu1.jpg"
            alt="Card cap"
          />
          <CardBody className="no-mp">
            <CardTitle
              className="titre-actu"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {t("environment.label")}
            </CardTitle>
          </CardBody>
        </Card>

        <Card className="card-actu">
          <CardImg
            top
            width="100%"
            src="/images/accueil/actu2.jpg"
            alt="Card cap"
          />
          <CardBody className="no-mp">
            <CardTitle
              className="titre-actu"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {t("poverty.label")}
            </CardTitle>
          </CardBody>
        </Card>

        <Card className="card-actu">
          <CardImg
            top
            width="100%"
            src="/images/accueil/actu3.jpg"
            alt="Card cap"
          />
          <CardBody className="no-mp">
            <CardTitle
              className="titre-actu"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {t("solidarity.label")}
            </CardTitle>
          </CardBody>
        </Card>
      </Row>
      <div>
        <div className="img-paralax">
          <Card className="card-action">
            <p className="titre-h3">{t("in_action.label")}</p>
            <p className="normal-text" style={{ padding: "0 75px 60px 75px" }}>
              {t("We want to allow everyone to get involved.label")}
            </p>
          </Card>
        </div>
      </div>

      {/* <div>
        <Row
          className="bg-home-2"
          style={{
            paddingTop: "110px",
            display: "flex",
            justifyContent: "center",
          }}
        >
           <div className="card-info">
            <CardBody className="no-mp">
              <CardTitle
                className="titre-actu"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                Titre du texte
              </CardTitle>
              <CardText className="normal-text">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua.
              </CardText>
            </CardBody>
          </div> 
           <div className="card-info">
            <CardBody className="no-mp">
              <CardTitle
                className="titre-actu"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                Titre du texte
              </CardTitle>
              <CardText className="normal-text">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua.
              </CardText>
            </CardBody>
          </div>
          <div className="card-info">
            <CardBody className="no-mp">
              <CardTitle
                className="titre-actu"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                }}
              >
                Titre du texte
              </CardTitle>
              <CardText className="normal-text">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua.
              </CardText>
            </CardBody>
          </div>
        </Row>
      </div> */}

      <Footer />
    </div>
  );
};

export default ScreenHome;
