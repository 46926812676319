import React, { useState } from "react";
import { Label, Input } from "reactstrap";
import "./App.css";
import { Link, useNavigate } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

import { fetchApi } from "./utils/api";

const ScreenConnectionBenevoles = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [signInEmail, setSignInEmail] = useState("");
  const [signInPassword, setSignInPassword] = useState("");
  const [assoExists, setAssoExists] = useState(false);
  const [beneExists, setBeneExists] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const [profilCompleted, setProfilCompleted] = useState(false);

  const [listErrorsSignin, setErrorsSignin] = useState([]);

  const [, setCookie] = useCookies(["token"]);

  var handleSubmitSignin = async () => {
    let upload = true;
    if (signInEmail === "" || signInPassword === "") {
      setErrorsSignin([t("pleasefill.label")]);
      upload = false;
    }

    if (upload) {
      const res = await fetchApi(
        "/users/sign-in",
        "POST",
        `emailFromFront=${signInEmail}&passwordFromFront=${signInPassword}`
      );

      console.log(res);

      if (res.result === true && res.userAssociation !== null) {
        if (rememberMe) {
          setCookie("token", res.userAssociation.token, { path: "/" });
          setCookie("type", "charity", { path: "/" });
        }
        props.addToken({
          token: res.userAssociation.token,
          userType: "charity",
        });
        if (res.userAssociation.completProfil === true) {
          setProfilCompleted(true);
          setAssoExists(true);
        } else {
          setAssoExists(true);
        }
      } else if (res.result === true && res.userBenevole !== null) {
        if (rememberMe) {
          setCookie("token", res.userBenevole.token, { path: "/" });
          setCookie("type", "volunteer", { path: "/" });
        }
        props.addToken({
          token: res.userBenevole.token,
          userType: "volunteer",
        });
        if (res.userBenevole.completProfil === true) {
          setProfilCompleted(true);
          setBeneExists(true);
        } else {
          setBeneExists(true);
        }
      } else {
        setErrorsSignin(res.error);
      }
    }
  };

  if (assoExists && profilCompleted) {
    navigate("/dashboardoeuvre");
  } else if (assoExists) {
    navigate("/inscriptionoeuvreformulaire");
  }
  if (beneExists && profilCompleted) {
    navigate("/dashboardbenevoles");

  } else if (beneExists) {
    navigate("/inscriptionbenevolesformulaire");

  }

  var tabErrorsSignin = listErrorsSignin.map((error, i) => {
    return <p style={{ marginLeft: "10%", color: "#DC3545" }}>{error}</p>;
  });

  return (
    <div className="full-width bg-inscription">
      <Header />
      <div className="full-width">
        <div style={{ marginBottom: "6.64%" }}>
          <h1 className="titre-h1-page">{t("connection.label")}</h1>
          <div className="formulaire-default">
            <Label className="formulaire-label" for="Prenom Benevole">
              {t("mail.label")}
            </Label>
            <Input
              value={signInEmail}
              onChange={(e) => setSignInEmail(e.target.value)}
              type="email"
              name="email"
              id="emailB"
              placeholder={t("Enter your email address.label")}
              bsSize="sm"
            />
          </div>
          <div className="formulaire-default">
            <Label className="formulaire-label" for="mdp">
              {t("password.label")}{" "}
            </Label>
            <Input
              value={signInPassword}
              onChange={(e) => setSignInPassword(e.target.value)}
              type="password"
              name="pas"
              id="firstnameB"
              placeholder={t("Enter your password.label")}
              bsSize="sm"
            />
          </div>
          <div className="formulaire-default">
            <Link
              className="formulaire-label facultatif forgot-link"
              to="/forgotpassword"
            >
              {t("forgotpassword.label")}
            </Link>
          </div>
          <div className="formulaire-default">
            <input
              defaultChecked={true}
              onChange={(e) => setRememberMe(e.target.checked)}
              className="checkbox"
              type="checkbox"
            />
            <span>{t("rememberme.label")}</span>
          </div>
          {tabErrorsSignin}
          <span
            onClick={() => handleSubmitSignin()}
            className="button-default-small"
            style={{
              marginLeft: "10%",
              marginTop: "40px !important",
              cursor: "pointer",
            }}
          >
            {t("connect.label")}
          </span>
        </div>
        <Footer />
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    addToken: function (userIdentification) {
      dispatch({ type: "addToken", userIdentification });
    },
  };
}

export default connect(null, mapDispatchToProps)(ScreenConnectionBenevoles);
