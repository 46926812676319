import React, { useState, useEffect } from "react";
import "./App.css";
import { Link, Navigate } from "react-router-dom";
import HeaderConnected from "./components/HeaderConnected";
import Footer from "./components/Footer";
import { Modal, Button, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { fetchApi } from "./utils/api";

const ScreenDashboardOeuvre = (props) => {
  const { t, i18n } = useTranslation();
  const [assoName, setAssoName] = useState("");
  const [projets, setProjets] = useState([]);

  const [applicants, setApplicants] = useState([]);
  const [benevole, setBenevole] = useState([]);

  const [refuseModal, setRefuseModal] = useState(false);
  const [applicantTokenToRefuse, setApplicantTokenToRefuse] = useState("");
  const [setApplicantNameToRefuse] = useState("");
  const [applicantIdToRefuse, setApplicantIdToRefuse] = useState("");
  const [projectToken, setProjectToken] = useState("");

  const [acceptModal, setAcceptModal] = useState(false);
  const [applicantTokenToAccept, setApplicantTokenToAccept] = useState("");
  const [setApplicantNameToAccept] = useState("");
  const [applicantIdToAccept, setApplicantIdToAccept] = useState("");
  const [projectTokenForAccpet, setProjectTokenForAccept] = useState("");
  const [fullApplicantToAccept, setFullApplicantToAccept] = useState("");

  const [cookies, removeCookie] = useCookies(["token"]);

  const token = props.userIdentification.token || cookies.token;
  const userType = props.userIdentification.userType || cookies.type;

  useEffect(() => {
    const assoDatas = async () => {
      const response = await fetchApi(
        `/dashboard-oeuvre?token=${token}`,
        "GET"
      );
      setProjets(response.dashboard.projets);
      setAssoName(response.dashboard.nomEntite);
      setApplicants(response.applicants);
      setBenevole(response.benevoles);
    };
    if (token) {
      assoDatas();
    }
  }, [token]);

  const handleValidateApply = async () => {
    setAcceptModal(false);
    const res = await fetchApi(
      "/validateApply",
      "POST",
      `applicantToken=${applicantTokenToAccept}&projectToken=${projectTokenForAccpet}`
    );

    if (res.result === true) {
      let applicantCopy = applicants.filter(
        (e) => e.benevole._id !== applicantIdToAccept
      );
      setApplicants(applicantCopy);
      setBenevole([...benevole, fullApplicantToAccept]);
    }
  };

  const handleRefuseApply = async () => {
    setRefuseModal(false);
    const res = await fetchApi(
      "/refuseApply",
      "POST",
      `applicantToken=${applicantTokenToRefuse}&projectToken=${projectToken}`
    );

    if (res.result === true) {
      let applicantCopy = applicants.filter(
        (e) => e.benevole._id !== applicantIdToRefuse
      );
      setApplicants(applicantCopy);
    }
  };
  const toggle = () => setRefuseModal(!refuseModal);

  const askForRefuse = (applicant) => {
    setApplicantIdToRefuse(applicant.benevole._id);
    setApplicantNameToRefuse(
      applicant.benevole.prenom + applicant.benevole.nom
    );
    setApplicantTokenToRefuse(applicant.benevole.token);
    setProjectToken(applicant.projectToken);
    setRefuseModal(true);
  };
  const toggleAccept = () => setAcceptModal(!acceptModal);

  const askForAccept = (applicant) => {
    setApplicantIdToAccept(applicant.benevole._id);
    setApplicantNameToAccept(
      applicant.benevole.prenom + applicant.benevole.nom
    );
    setApplicantTokenToAccept(applicant.benevole.token);
    setProjectTokenForAccept(applicant.projectToken);
    setFullApplicantToAccept(applicant);
    setAcceptModal(true);
  };

  const deleteAccount = async () => {
    const res = await fetchApi(`/users/delete-account?token=${token}&locale=${i18n.language}`, "DELETE");
    alert(t("mail.deleteAccount.label"));
    removeCookie("token");
    removeCookie("type");
  };

  let projetToDisplay = (
    <div
      className="card-dashboard"
      style={{ display: "flex", marginBottom: "30px" }}
    >
      {" "}
      <p className="titre-card-type-2 no-mp">
        {t("no project for the moment.label")}
      </p>
    </div>
  );

  if (projets.length > 0) {
    projetToDisplay = projets.map((projet, i) => {
      let projectSrc = "/images/home-bene/projet3.png";
      if (projet.category === "Environnement") {
        projectSrc = "/images/projectPic/environnemental.jpg";
      }
      if (projet.category === "Solidarité") {
        projectSrc = "/images/projectPic/solidarity.jpg";
      }
      if (projet.category === "Pauvreté") {
        projectSrc = "/images/projectPic/poverty.jpg";
      }
      return (
        <div
          key={i}
          className="card-dashboard"
          style={{ display: "flex", marginBottom: "30px" }}
        >
          <div>
            <img
              className="image-dashboard"
              src={projectSrc}
              style={{ width: "240px", height: "auto" }}
              alt="a generic background"
            />
          </div>
          <div className="dashboard-content">
            <p className="titre-card-type-2 no-mp">{projet.title}</p>
            <p className="text-dashboard">{projet.desc}</p>
          </div>
          <div>
            <Link
              onClick={() => props.projectDetails(projet)}
              to={`/gestionprojet/${projet.projectToken}`}
              className="button-default"
            >
              {t("manage the project.label")}
            </Link>
          </div>
        </div>
      );
    });
  }

  let applicantsToDisplay = (
    <div
      className="card-dashboard"
      style={{ display: "flex", marginBottom: "30px" }}
    >
      {" "}
      <p className="titre-card-type-2 no-mp">
        {t("no applicant for the moment.label")}
      </p>
    </div>
  );
  if (applicants.length > 0) {
    applicantsToDisplay = applicants.map((applicant, i) => {
      return (
        <div key={i} style={{ marginBottom: "40px" }}>
          <div className="card-dashboard" style={{ display: "flex" }}>
            <div>
              <img
                className="image-dashboard-asso"
                src="/images/skill-bank/panda-bear.png"
                alt="a generic background"
              />
            </div>
            <div className="dashboard-content-asso">
              <Link to={`/profil/${applicant.benevole.token}`} className="link">
                <p className="titre-card-type-2 no-mp">
                  {applicant.benevole.prenom} {applicant.benevole.nom}
                </p>
              </Link>
              <p className="text-dashboard">{applicant.benevole.desc}</p>
            </div>
            <div>
              <span
                onClick={() =>
                  window.open(`mailto:${applicant.benevole.email}`, "_blank")
                }
                style={{ cursor: "pointer" }}
                className="button-default-small"
              >
                {t("contact.label")}
              </span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => askForAccept(applicant)}
                className="button-default-small"
              >
                {t("validate application.label")}
              </span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => askForRefuse(applicant)}
                className="button-default-small-red"
              >
                {t("refuse application.label")}
              </span>
            </div>
          </div>
        </div>
      );
    });
  }
  let benevoleToDisplay = (
    <div
      className="card-dashboard"
      style={{ display: "flex", marginBottom: "30px" }}
    >
      {" "}
      <p className="titre-card-type-2 no-mp">
        {t("no volunteer for the moment.label")}
      </p>
    </div>
  );

  if (benevole.length > 0) {
    benevoleToDisplay = benevole.map((benevole, i) => {
      return (
        <div key={i} style={{ marginBottom: "40px" }}>
          <div className="card-dashboard" style={{ display: "flex" }}>
            <div>
              <img
                className="image-dashboard-asso"
                src="/images/skill-bank/panda-bear.png"
                alt="a generic background"
              />
            </div>
            <div className="dashboard-content-asso">
              <Link to={`/profil/${benevole.benevole.token}`} className="link">
                {" "}
                <p className="titre-card-type-2 no-mp">
                  {benevole.benevole.prenom} {benevole.benevole.nom}
                </p>
              </Link>
              <p className="text-dashboard">{benevole.benevole.desc}</p>
            </div>
            <div></div>
          </div>
        </div>
      );
    });
  }
  if (!token || userType !== "charity") {
    return <Navigate to="/" />;
  }

  return (
    <div className="full-width bg-dashboard-asso">
      <HeaderConnected show={false} oeuvre />
      <div
        className="full-width"
        style={{ position: "relative", bottom: "110px" }}
      >
        <>
          <Modal isOpen={refuseModal} toggle={toggle}>
            <ModalBody>{t("reject application.label")}</ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => setRefuseModal(false)}>
                Annuler
              </Button>
              <Button color="secondary" onClick={() => handleRefuseApply()}>
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </>
        <>
          <Modal isOpen={acceptModal} toggle={toggleAccept}>
            <ModalBody>{t("acceptapplication.label")}</ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => setAcceptModal(false)}>
                Annuler
              </Button>
              <Button color="secondary" onClick={() => handleValidateApply()}>
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </>
        <h1 className="titre-h1-page">
          {t("welcome.label")} {assoName && assoName.toUpperCase()} !
        </h1>

        <div style={{ marginBottom: "50px", display: "flex", justifyContent: "space-between", padding: '0 10%', flexWrap: "wrap" }}>
          <Link
            to="/creationprojet"
            className="button-default-purple"
          >
            {t("create project.label")}
          </Link>

          <Link
            to="/skillbank"
            className="button-default"
            style={{marginTop: 10}}
          >
            {t("search skill bank.label")}
          </Link>
        </div>

        <h2 className="titre-h2-page">{t("my projects.label")} </h2>

        {projetToDisplay}

        <h2 className="titre-h2-page">{t("my applicants.label")}</h2>
        <div className="applicant_container">{applicantsToDisplay}</div>
        <h2 className="titre-h2-page">{t("my volunteer.label")}</h2>
        <div className="volunteer_container">{benevoleToDisplay}</div>
        <div className="card-dashboard">
          <button style={{border: "none"}} onClick={deleteAccount} className="button-default-small-purple">{t("deleteaccount.label")}</button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

function mapStateToProps(state) {
  return { userIdentification: state.token };
}

function mapDispatchToProps(dispatch) {
  return {
    projectDetails: function (project) {
      return dispatch({ type: "addValue", value: project });
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenDashboardOeuvre);
