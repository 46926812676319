import React from "react";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className="footer">
        <Link to="/" className="logo-footer">
          ACTOMOBILE
        </Link>
        <div style={{ marginLeft: "auto"}} className="footer-links-wrapper">
          <Link to="/mentionslegales" className="nav-item-footer">
            {t("legal_notices.label")}
          </Link>
          <Link to="/apropos" className="nav-item-footer">
            {t("about.label")}
          </Link>
          <Link to="/formulairecontact" className="nav-item-footer">
            {t("contact.label")}
          </Link>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => i18n.changeLanguage("en")}
            className="nav-item-footer"
          >
            Anglais
          </span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => i18n.changeLanguage("fr")}
            className="nav-item-footer"
          >
            Français
          </span>
        </div>
      </div>
      <div className="sub-footer">
        <div>Copyright Actomobile © 2022</div>
        <ul className="links">
          <li><Link to="/conditionsgenerales">{t("generalconditions.label")}</Link></li>
          <li><Link to="/politiqueconfidentialite">{t("contidentialitypolitic.label")}</Link></li>
          <li><Link to="/politiquecookies">{t("cookiespolitic.label")}</Link></li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
