import React, { useState, useEffect } from "react";
import "./App.css";
import { Navigate } from "react-router-dom";
import HeaderConnected from "./components/HeaderConnected";
import Footer from "./components/Footer";
import { useParams, Link } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Modal, Button, ModalBody, ModalFooter } from "reactstrap";
import { fetchApi } from "./utils/api";

const ScreenProjetDetail = ({ userIdentification }) => {
  const { t } = useTranslation();
  const { projectToken } = useParams();
  const [projectData, setProjectData] = useState();
  const [skills, setSkills] = useState([]);

  const [contactEmail, setContactEmail] = useState();
  const [message, setMessage] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [succesMessage, setSuccesMessage] = useState("");
  const [cookies] = useCookies(["token"]);
  const [charityDesc, setCharityDesc] = useState("");
  const [projectSrc, setProjectSrc] = useState("/images/home-bene/projet3.png");

  const [succesModal, setSuccessModal] = useState(false);

  const tokenId = userIdentification.token || cookies.token;
  const userType = userIdentification.userType || cookies.type;

  useEffect(() => {
    const loadProjectData = async () => {
      const res = await fetchApi(
        `/getProjectData?token=${projectToken}`,
        "GET"
      );

      setProjectData(res.project);
      setSkills(res.project.competence);
      setCharityDesc(res.charity.desc);
      console.log(res.project.category);
      if (res.project.category === "Environnement") {
        setProjectSrc("/images/projectPic/environnemental.jpg");
      }
      if (res.project.category === "Solidarité") {
        setProjectSrc("/images/projectPic/solidarity.jpg");
      }
      if (res.project.category === "Pauvreté") {
        setProjectSrc("/images/projectPic/poverty.jpg");
      }
    };
    if (projectToken) {
      loadProjectData();
    }
  }, [projectToken]);

  const handleApply = async () => {
    const res = await fetchApi(
      "/apply_project",
      "POST",
      `token=${tokenId}&projectToken=${projectToken}&contactEmail=${contactEmail}&message=${message}`
    );

    console.log(res.result);
    if (res.result === false) {
      setErrorMessage(t("cantapply.label"));
    }
    if (res.result === true) {
      setSuccessModal(true);
      setSuccesMessage(t("succesapplication.label"));
    }
  };

  const toggle = () => setSuccessModal(!succesModal);

  if (!tokenId || userType !== "volunteer") {
    return <Navigate to="/" />;
  }

  return (
    <div className="full-width bg-projet-detail">
      <HeaderConnected />
      <div className="full-width">
        <>
          <Modal isOpen={succesModal} toggle={toggle}>
            <ModalBody>{succesMessage}</ModalBody>
            <ModalFooter>
              <Link to="/dashboardbenevoles">
                <Button color="secondary">OK</Button>
              </Link>
            </ModalFooter>
          </Modal>
        </>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            bottom: "80px",
          }}
          className="title-wrapper"
        >
          <img
            className="image-projet-detail"
            src={projectSrc}
            alt="a generic background"
          />

          <div>
            <h1 className="titre-h1-page">{projectData?.title}</h1>
            <p className="text-projet-detail">{charityDesc}</p>
          </div>
        </div>

        <div style={{ marginTop: "40px" }}>
          <div className="card-projet-detail" style={{ display: "flex" }}>
            <div>
              <p className="titre-compétences">{t("require skills.label")} </p>
              {skills.map((skill, i) => {
                return (
                  <p key={i} className="texte-competences-liste">
                    {skill.label}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
        <div style={{ marginTop: "50px" }}>
          <div className="card-projet-detail">
            <p className="text-dashboard">{projectData?.desc}</p>
          </div>
        </div>
        <div style={{ marginTop: "50px" }}>
          <div className="card-projet-detail">
            <div className="input_container">
              <input
                onChange={(e) => setContactEmail(e.target.value)}
                value={contactEmail}
                className="input"
                placeholder={t("contact mail.label")}
              />
              <textarea
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                className="text_area"
                placeholder={t("message to charity.label")}
              />
            </div>
            <div
              style={{
                marginTop: "60px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span
                onClick={() => handleApply()}
                style={{ cursor: "pointer" }}
                className="button-default"
              >
                {t("apply.label")}
              </span>
            </div>
            <div
              style={{
                marginTop: "60px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {errorMessage ? (
                <p className="error_message">{errorMessage}</p>
              ) : null}
              {/* {succesMessage ? <p className='succes_message'>{succesMessage}</p> : null} */}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { userIdentification: state.token };
}

export default connect(mapStateToProps, null)(ScreenProjetDetail);
