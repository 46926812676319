import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const CardProjectBank = (props) => {
  const { t } = useTranslation();
  let skillList = props.skills.map((skill, i) => {
    return <p className="competences-liste">{skill.value}</p>;
  });

  let image = (
    <div>
      <img
        style={{ width: "300px", margin: "10px" }}
        className="image-skill-bank"
        src={props.src}
        alt="a generic background"
      />
    </div>
  );

  return (
    <div style={{ marginBottom: "40px" }}>
      <div className="card-skill-bank" style={{ display: "flex" }}>
        {image}
        <div style={{ width: "250px" }}>
          <p className="titre-card-type-2-project no-mp">{props.title}</p>
          <p className="sous-titre-card-type-2">{t("require skills.label")} </p>
          {skillList}
        </div>
        <div className="skill-bank-content">
          <p className="text-project-bank">{props.desc}</p>
        </div>
        <div>
          <Link
            to={`/projetdetail/${props.projectToken}`}
            className="button-default"
          >
            {t("show.label")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CardProjectBank;
