import React, { useState, useEffect } from "react";
import { Label, Input } from "reactstrap";
import "./App.css";
import { Link, Navigate } from "react-router-dom";
import Footer from "./components/Footer";
import { connect } from "react-redux";
import Select from "react-select";
import HeaderConnected from "./components/HeaderConnected";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { fetchApi } from "./utils/api";

const ScreenEditProject = (props) => {
  const { t } = useTranslation();
  const { projectToken } = useParams();
  const [cookies] = useCookies(["token"]);

  const token = props.userIdentification.token || cookies.token;
  const userType = props.userIdentification.userType || cookies.type;

  const selectCategory = [
    {
      value: "Environnement",
      label: "Environnement",
    },
    {
      value: "Pauvreté",
      label: "Pauvreté",
    },
    {
      value: "Solidarité",
      label: "Solidarité",
    },
  ];

  const selectCompetence = [
    {
      value: "Agriculture",
      label: t("Agriculture.label"),
    },
    {
      value: "Public speaking",
      label: t("Public speaking.label"),
    },
    {
      value: "Biology",
      label: t("Biology.label"),
    },
    {
      value: "Communication",
      label: t("Communication.label"),
    },
    {
      value: "Construction",
      label: t("Construction.label"),
    },
    {
      value: "UX design",
      label: t("UX design.label"),
    },
    {
      value: "Consulting",
      label: t("Consulting.label"),
    },
    {
      value: "Cloud Computing",
      label: t("Cloud Computing.label"),
    },
    {
      value: "Writing",
      label: t("Writing.label"),
    },
    {
      value: "Education",
      label: t("Education.label"),
    },
    {
      value: "Electrician",
      label: t("Electrician.label"),
    },
    {
      value: "Electronic",
      label: t("Electronic.label"),
    },
    {
      value: "Event",
      label: t("Event.label"),
    },
    {
      value: "Finance",
      label: t("Finance.label"),
    },
    {
      value: "AI (Artificial Intelligence)",
      label: t("AI (Artificial Intelligence).label"),
    },
    {
      value: "Nurse",
      label: t("Nurse.label"),
    },
    {
      value: "Computing",
      label: t("Computing.label"),
    },
    {
      value: "Legal",
      label: t("Legal.label"),
    },
    {
      value: "Masonry",
      label: t("Masonry.label"),
    },
    {
      value: "Marketing",
      label: t("Marketing.label"),
    },
    {
      value: "Mechanic",
      label: t("Mechanic.label"),
    },
    {
      value: "Doctor",
      label: t("Doctor.label"),
    },
    {
      value: "Carpenter",
      label: t("Carpenter.label"),
    },
    {
      value: "Musician",
      label: t("Musician.label"),
    },
    {
      value: "Oceanology",
      label: t("Oceanology.label"),
    },
    {
      value: "Plumbery",
      label: t("Plumbery.label"),
    },
    {
      value: "Video production",
      label: t("Video production.label"),
    },
    {
      value: "School teacher",
      label: t("School teacher.label"),
    },
    {
      value: "Press relation",
      label: t("Press relation.label"),
    },
    {
      value: "Repair",
      label: t("Repair.label"),
    },
    {
      value: "Social Media",
      label: t("Social Media.label"),
    },
    {
      value: "Translation",
      label: t("Translation.label"),
    },
    {
      value: "UX Design",
      label: t("UX Design.label"),
    },
    {
      value: "Veterinarian",
      label: t("Veterinarian.label"),
    },
    {
      value: "Web Analytic",
      label: t("Web Analytic.label"),
    },
    {
      value: "Web design",
      label: t("Web design.label"),
    },
    {
      value: "Web development",
      label: t("Web development.label"),
    },
    {
      value: "Other",
      label: t("Other.label"),
    },
  ];

  const selectDispo = [
    {
      value: "À distance",
      label: "À distance",
    },
    {
      value: "Locale",
      label: "Locale",
    },
    {
      value: "Les Deux",
      label: "Les Deux",
    },
  ];

  const selectVisibilite = [
    {
      value: "Publique",
      label: `${t("public.label")}`,
    },
    {
      value: "Limitée",
      label: `${t("project limited.label")}`,
    },
    {
      value: "Privée",
      label: `${t("private project.label")}`,
    },
  ];

  const [title, setTitle] = useState();
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [city, setCity] = useState("");
  const [desc, setDesc] = useState("");
  const [comprec, setComprec] = useState([]);
  const [category, setCategory] = useState(null);
  const [dispo, setDispo] = useState(null);
  const [visibility, setVisibility] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadProjectData = async () => {
      const result = await fetchApi(
        `/getProjectData?token=${projectToken}`,
        "GET"
      );

      setTitle(result.project.title);
      console.log(result.project.endDate.split("T")[0]);
      setEndDate(result.project.endDate.split("T")[0]);
      setStartDate(result.project.startDate.split("T")[0]);
      setDesc(result.project.desc);
      setCity(result.project.city);
      if (result.project.category) {
        setCategory({
          label: result.project.category,
          value: result.project.category,
        });
      }
      if (result.availability) {
        setDispo({
          label: result.project.availability,
          value: result.project.availability,
        });
      }
      let tempTab = [];
      for (let i = 0; i < result.project.competence.length; i++) {
        tempTab.push({
          value: result.project.competence[i].value,
          label: result.project.competence[i].label,
        });
      }
      if (result.project.visibility === "Publique") {
        setVisibility({
          value: result.project.visibility,
          label: `${t("public.label")}`,
        });
      }
      if (result.project.visibility === "Limitée") {
        setVisibility({
          value: result.project.visibility,
          label: `${t("project limited.label")}`,
        });
      }
      if (result.project.visibility === "Privée") {
        setVisibility({
          value: result.project.visibility,
          label: `${t("private project.label")}`,
        });
      }
      setComprec(tempTab);
      setLoading(false);
    };
    if (projectToken) {
      loadProjectData();
      setLoading(true);
    }
  }, [projectToken, t]);

  var handleSavedProject = async () => {
    await fetchApi(
      "/edit-project",
      "POST",
      `titleFromFront=${title}&endDateFromFront=${endDate}&startDateFromFront=${startDate}
            &cityFromFront=${city}&descFromFront=${desc}&comprecFromFront=${JSON.stringify(
        comprec
      )}
            &categoryFromFront=${category.value}&dispoFromFront=${
        dispo.value
      }&visibilityFromFront=${visibility.value}&token=${projectToken}`
    );
  };

  var handleChangeDispo = (value) => {
    setDispo(value);
  };

  var handleChangeVisible = (value) => {
    setVisibility(value);
  };

  var handleCategory = (value) => {
    setCategory(value);
  };

  const handleChange = (value) => {
    setComprec(value);
  };

  if (!token || userType !== "charity") {
    return <Navigate to="/" />;
  }
  return (
    <div className="full-width bg-inscription-formulaire-asso">
      <HeaderConnected show={false} oeuvre />
      <div
        className="full-width"
        style={{ position: "relative", bottom: "110px" }}
      >
        <div>
          <h1 className="titre-h1-page">{t("edit project.label")}</h1>
          <div className="full-width">
            {loading ? null : (
              <>
                <div className="formulaire-default">
                  <Label for="titre" className="formulaire-label">
                    {t("title.label")}
                  </Label>
                  <Input
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    type="titre"
                    name="titre"
                    id="titre"
                    placeholder={t("title placeholder.label")}
                    bsSize="sm"
                  />
                </div>

                <div className="formulaire-default">
                  <Label for="exampleSelect" className="formulaire-label">
                    {t("category.label")}
                  </Label>
                  <Select
                    defaultValue={category}
                    placeholder={t("project category.label")}
                    options={selectCategory}
                    onChange={handleCategory}
                  />
                </div>

                <div className="formulaire-default">
                  <Label for="date" className="formulaire-label">
                    {t("start date.label")}
                  </Label>
                  <Input
                    onChange={(e) => setStartDate(e.target.value)}
                    value={startDate}
                    type="date"
                    name="date"
                    id="date"
                    placeholder=""
                    bsSize="sm"
                  />
                </div>

                <div className="formulaire-default">
                  <Label for="date" className="formulaire-label">
                    {t("end date.label")}
                  </Label>
                  <Input
                    onChange={(e) => setEndDate(e.target.value)}
                    value={endDate}
                    type="date"
                    name="date"
                    id="date"
                    placeholder=""
                    bsSize="sm"
                  />
                </div>

                <div className="formulaire-default">
                  <Label for="exampleSelect" className="formulaire-label">
                    {t("Your availability.label")}{" "}
                  </Label>
                  <Select
                    defaultValue={dispo}
                    placeholder="Veuillez-choisir votre mode de participation"
                    options={selectDispo}
                    onChange={handleChangeDispo}
                  />
                </div>

                <div className="formulaire-default">
                  <Label for="ville" className="formulaire-label">
                    {t("city.label")}
                  </Label>
                  <Input
                    onChange={(e) => setCity(e.target.value)}
                    value={city}
                    type="ville"
                    name="ville"
                    id="ville"
                    placeholder="Paris"
                    bsSize="sm"
                  />
                </div>

                <div className="formulaire-default">
                  <Label for="exampleSelect" className="formulaire-label">
                    {t("Your visibility.label")}
                  </Label>
                  <Select
                    defaultValue={visibility}
                    placeholder={t(
                      "Please choose the visibility of your profile.label"
                    )}
                    options={selectVisibilite}
                    onChange={handleChangeVisible}
                  />
                </div>

                <div className="formulaire-default">
                  <Label for="exampleSelectMulti" className="formulaire-label">
                    {t("select require skills.label")}{" "}
                  </Label>
                  <Select
                    placeholder={t("Please choose the required skills.label")}
                    value={comprec}
                    options={selectCompetence}
                    onChange={handleChange}
                    isMulti
                  />
                </div>

                <div className="formulaire-default">
                  <Label
                    for="exampleText"
                    className="formulaire-label facultatif"
                  >
                    {t("your description.label")}
                  </Label>
                  <Input
                    onChange={(e) => setDesc(e.target.value)}
                    value={desc}
                    type="textarea"
                    name="text"
                    id="experience-pro"
                    placeholder={t(
                      "Please write a few words about your project.label"
                    )}
                    bsSize="sm"
                  />
                </div>
              </>
            )}
          </div>
          <Link
            to="/dashboardoeuvre"
            className="button-default-small"
            style={{ marginLeft: "10%", marginTop: "40px !important" }}
          >
            Annuler
          </Link>
          <Link
            onClick={() => handleSavedProject()}
            to="/dashboardoeuvre"
            className="button-default-small"
            style={{ marginLeft: "10%", marginTop: "40px !important" }}
          >
            MODIFIER LE PROJET
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

function mapStateToProps(state) {
  return { userIdentification: state.token };
}

export default connect(mapStateToProps, null)(ScreenEditProject);
