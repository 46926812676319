import React from "react";
import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useTranslation } from "react-i18next";
const ScreenAPropos = (props) => {
  const { t } = useTranslation();
  return (
    <div className="full-width">
      <Header />
      <div className="full-width">
        <h2 className="titre-h1-page">{t("about.label")}</h2>
        <p
          className="normal-text"
          style={{ marginLeft: "8%", marginRight: "10%", textAlign: "left" }}
        >
          {t("aboutus.label")}
        </p>
        <Footer />
      </div>
    </div>
  );
};

export default ScreenAPropos;
