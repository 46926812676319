import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import "./i18n";
import ScreenHome from "./ScreenHome";
import ScreenHomeBenevole from "./ScreenHomeBenevoles";
import ScreenHomeOeuvre from "./ScreenHomeOeuvre";
import ScreenInscriptionBenevoles from "./ScreenInscriptionBenevoles";
import ScreenConnectionBenevoles from "./ScreenConnectionBenevoles";
import ScreenInscriptionBenevolesFormulaire from "./ScreenInscriptionBenevolesFormulaire";
import ScreenDashboardBenevoles from "./ScreenDashboardBenevoles";
import ScreenProjectBank from "./ScreenProjectBank";
import ScreenSkillBank from "./ScreenSkillBank";
import ScreenProjetDetail from "./ScreenProjetDetail";
import ScreenProjetAssoDetail from "./ScreenProjetAssoDetail";
import ScreenProfilBenevole from "./ScreenProfilBenevole";
import ScreenInscriptionOeuvreFormulaire from "./ScreenInscriptionOeuvreFormulaire";
import ScreenDashboardOeuvre from "./ScreenDashboardOeuvre";
import ScreenCreationProjet from "./ScreenCreationProjet";
import ScreenEditProject from "./ScreenEditProject";
import ScreenMentionsLegales from "./ScreenMentionsLegales";
import ScreenAPropos from "./ScreenAPropos";
import ScreenGestionProjet from "./ScreenGestionProjet";
import ScreenProfilView from "./ScreenProfilView";
import ScreenForgotPassword from "./ScreenForgotPassword";
import ScreenNewPassword from "./ScreenNewPassword";

import { Provider } from "react-redux";
import { createStore, combineReducers } from "redux";
import HttpsRedirect from 'react-https-redirect';

import token from "./reducers/token";
import projectToken from "./reducers/projectToken";
import dataProject from "./reducers/dataProject";
import ScreenConditionsGenerales from "./ScreenConditionsGenerales";
import ScreenPolitiqueConfidentialite from "./ScreenPolitiqueConfidentialite";
import ScreenPolitiqueCookies from "./ScreenPolitiqueCookies";
import ScreenFormulaireContact from "./ScreenFormulaireContact";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";

const store = createStore(
  combineReducers({ token, projectToken, dataProject })
);

function App() {
  const { t, i18n } = useTranslation();

  return (
    <Provider store={store}>
      <HttpsRedirect>
        <BrowserRouter>
          <CookieConsent
            location="bottom"
            buttonText={t("yes")}
            style={{ background: "#2B373B" }}
            buttonStyle={{ backgroundColor: "rgb(85, 194, 181)", fontSize: "13px" }}
          >
            {t("cookie.consent")}
          </CookieConsent>
          <Routes>
            <Route element={<ScreenHome />} path="/" exact />
            <Route
              element={<ScreenHomeBenevole />}
              path="homebenevoles"
              exact
            />
            <Route
              element={<ScreenHomeOeuvre />}
              path="homeoeuvres"
              exact
            />
            <Route
              element={<ScreenInscriptionBenevoles />}
              path="inscriptionbenevoles"
              exact
            />
            <Route
              element={<ScreenConnectionBenevoles />}
              path="connectionbenevoles"
              exact
            />
            <Route
              element={<ScreenInscriptionBenevolesFormulaire />}
              path="inscriptionbenevolesformulaire"
              exact
            />
            <Route
              element={<ScreenFormulaireContact />}
              path="formulairecontact"
              exact
            />
            <Route
              element={<ScreenDashboardBenevoles />}
              path="dashboardbenevoles"
              exact
            />
            <Route
              element={<ScreenProjectBank />}
              path="projectbank"
              exact
            />
            <Route
              element={<ScreenProjetDetail />}
              path="projetdetail/:projectToken"
              exact
            />
            <Route
              element={<ScreenProfilBenevole />}
              path="profilbenevole"
              exact
            />
            <Route
              element={<ScreenInscriptionOeuvreFormulaire />}
              path="inscriptionoeuvreformulaire"
              exact
            />
            <Route
              element={<ScreenDashboardOeuvre />}
              path="dashboardoeuvre"
              exact
            />
            <Route
              element={<ScreenCreationProjet />}
              path="creationprojet"
              exact
            />
            <Route
              element={<ScreenProjetAssoDetail />}
              path="projetassodetail"
              exact
            />
            <Route element={<ScreenSkillBank />} path="skillbank" exact />
            <Route
              element={<ScreenEditProject />}
              path="editproject/:projectToken"
              exact
            />
            <Route
              element={<ScreenMentionsLegales />}
              path="mentionslegales"
              exact
            />
            <Route
              element={<ScreenConditionsGenerales />}
              path="conditionsgenerales"
              exact
            />
            <Route
              element={<ScreenPolitiqueConfidentialite />}
              path="politiqueconfidentialite"
              exact
            />
            <Route
              element={<ScreenPolitiqueCookies />}
              path="politiquecookies"
              exact
            />
            <Route element={<ScreenAPropos />} path="apropos" exact />
            <Route
              element={<ScreenGestionProjet />}
              path="gestionprojet/:projectToken"
              exact
            />
            <Route
              element={<ScreenProfilView />}
              path="profil/:userToken"
              exact
            />
            <Route
              element={<ScreenForgotPassword />}
              path="forgotpassword"
              exact
            />
            <Route
              element={<ScreenNewPassword />}
              path="new-password/:token"
              exact
            />
          </Routes>
        </BrowserRouter>
      </HttpsRedirect>
    </Provider>
  );
}

export default App;
