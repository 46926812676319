import React, { useState, useEffect } from "react";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandsHelping, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link, Navigate } from "react-router-dom";
import HeaderConnected from "./components/HeaderConnected";
import Footer from "./components/Footer";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { fetchApi } from "./utils/api";

const ScreenProfilBenevole = ({ userIdentification }) => {
  const { t, i18n } = useTranslation();
  const [profilData, setProfilData] = useState();
  const [skills, setSkills] = useState([]);
  const [cookies, removeCookie] = useCookies(["token"]);

  const tokenId = userIdentification.token || cookies.token;
  const userType = userIdentification.userType || cookies.type;

  useEffect(() => {
    const loadProfilData = async () => {
      const res = await fetchApi(`/dashboardBenevole?token=${tokenId}`);
      console.log(res);
      setProfilData(res);
      setSkills(res.competences);
    };
    if (tokenId) {
      loadProfilData();
    }
  }, [tokenId]);

  const handleDeleteSkill = (value) => {
    console.log(value);
  };

  const deleteAccount = async () => {
    const res = await fetchApi(`/users/delete-account?token=${tokenId}&locale=${i18n.language}`);
    alert(t("mail.deleteAccount.label"));
    removeCookie("token");
    removeCookie("type");
  };

  if (!tokenId || userType !== "volunteer") {
    return <Navigate to="/" />;
  }
  console.log(profilData)
  return (
    <div className="full-width bg-profil">
      <HeaderConnected show={false} />
      <div className="full-width profil_container">
        <h1 className="titre-h1-page">{t("profil.label")} </h1>

        <div>
          <div className="card-dashboard">
            <div>
              <img
                className="image-dashboard-profil"
                src="/images/skill-bank/panda-bear.png"
                alt="a generic background"
              />
            </div>
            <div className="dashboard-content" style={{ alignItems: "center" }}>
              <p className="titre-card-type-2 no-mp">
                {profilData?.prenom} {profilData?.nom}
              </p>
            </div>
            <div style={{marginTop: 20}}>
              <Link
                to="/inscriptionbenevolesformulaire"
                className="button-default-small-purple"
              >
                {t("edit.label")}
              </Link>
            </div>
          </div>
          <div className="card-dashboard">
            <p className="text-dashboard-profil">{profilData?.desc}</p>
          </div>
        </div>

        <div>
          <h2 className="titre-h2-page rmv-mb">{t("my skills.label")}</h2>

          {/* <div className='formulaire-default-profil'>
                        <Label for="exampleSelectMulti" className='formulaire-label'>Sélectionnez vos compétences</Label>
                        <Input type="select" name="selectMulti" id="examplSelectMulti" multiple placeholder="Veuillez-choisir vos compétences" bsSize="sm">
                        <option>Compétence 1</option>
                        <option>Compétence 2</option>
                        <option>Compétence 3</option>
                        <option>Compétence 4</option>
                        <option>Compétence 5</option>
                        </Input>
                    </div>
                    <Link className='button-default-small-purple' style={{margin:'20px 0 0 10%'}}>Valider</Link> */}
        </div>

        {skills.map((skill, i) => {
          return (
            <div style={{ display: "flex" }}>
              <div className="liste-de-competence-profil">
                <p className="text-competence-profil">
                  <FontAwesomeIcon
                    onClick={() => handleDeleteSkill(skill)}
                    icon={faHandsHelping}
                    style={{
                      color: "#262626",
                      marginRight: "25px",
                      marginBottom: "0px",
                      fontSize: "22px",
                    }}
                  />
                  {skill.label}
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{
                      color: "#262626",
                      float: "right",
                      marginTop: "4px",
                      fontSize: "22px",
                    }}
                  />
                </p>
              </div>
            </div>
          );
        })}

        <h2 className="titre-h2-page rmv-mb">{t("my convictions.label")}</h2>
        {/* <Link className='button-default-small-purple' style={{marginLeft:''}}>EDITER</Link> */}
        <div className="card-dashboard">
          <p className="text-dashboard-profil">{profilData?.convictions}</p>
        </div>
        <div className="card-dashboard">
          <button style={{border: "none"}} onClick={deleteAccount} className="button-default-small-purple">{t("deleteaccount.label")}</button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

function mapStateToProps(state) {
  return { userIdentification: state.token };
}

export default connect(mapStateToProps, null)(ScreenProfilBenevole);
