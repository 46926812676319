import React, { useState, useEffect } from "react";
import "./App.css";
import { Link, Navigate } from "react-router-dom";
import Footer from "./components/Footer";
import HeaderConnected from "./components/HeaderConnected";
import { connect } from "react-redux";
import { Modal, Button, ModalBody, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { fetchApi } from "./utils/api";

const ScreenDashboardBenevoles = ({ userIdentification }) => {
  const { t } = useTranslation();
  const [profilData, setProfilData] = useState();
  const [projectsData, setProjectsData] = useState([]);
  const [applicationData, setApplicationData] = useState([]);

  const [quitModal, setQuitModal] = useState(false);
  const [projectTokenToQuit, setProjectTokenToQuit] = useState("");
  const [projectNameToQuit, setProjectNameToQuit] = useState("");
  const [projectIdToQuit, setProjectIdToQuit] = useState("");

  const [cancelModal, setCancelModal] = useState(false);
  const [projectTokenToCancel, setProjectTokenToCancel] = useState("");
  const [projectNameToCancel, setProjectNameToCancel] = useState("");
  const [projectIdToCancel, setProjectIdToCancel] = useState("");

  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const [cookies] = useCookies(["token"]);

  const tokenId = userIdentification.token || cookies.token;
  const userType = userIdentification.userType || cookies.type;

  useEffect(() => {
    const loadDashboardData = async () => {
      const res = await fetchApi(`/dashboardBenevole?token=${tokenId}`);

      console.log("result =====>", res);
      setProfilData(res);
      setProjectsData(res.projets);
      setApplicationData(res.application);
    };
    if (tokenId) {
      loadDashboardData();
    }
  }, [tokenId]);

  const handleCancelApplication = async () => {
    setCancelModal(false);
    const res = await fetchApi(
      "/cancel-application",
      "POST",
      `token=${tokenId}&projectToken=${projectTokenToCancel}`
    );

    if (res.result === true) {
      let applicantionCopy = applicationData.filter(
        (e) => e._id !== projectIdToCancel
      );
      setApplicationData(applicantionCopy);
    }
    if (
      res.result === false &&
      res.message === "application were already accept"
    ) {
      setErrorMessage(
        "Votre candidature à déja été acceptée, vous pouvez quitter le projet"
      );
      setErrorModal(true);
    }
    if (
      res.result === false &&
      res.message === "application were already refuse"
    ) {
      setErrorMessage("Votre candidature avait déjà été réfusée");
      setErrorModal(true);
    }
  };

  const handleQuitProject = async () => {
    setQuitModal(false);
    const res = await fetchApi(
      "/quit-project",
      "POST",
      `token=${tokenId}&projectToken=${projectTokenToQuit}`
    );

    if (res.result === true) {
      let projectCopy = projectsData.filter((e) => e._id !== projectIdToQuit);
      setProjectsData(projectCopy);
    }
    if (res.result === false) {
      setErrorMessage("Vous avez déjà été enlever du projet");
      setErrorModal(true);
    }
  };

  const toggle = () => setQuitModal(!quitModal);
  const toggleCancel = () => setCancelModal(!cancelModal);
  const toggleError = () => setErrorModal(!errorModal);

  const askForQuit = (project) => {
    setProjectNameToQuit(project.title);
    setProjectTokenToQuit(project.projectToken);
    setProjectIdToQuit(project._id);
    setQuitModal(true);
  };

  const askForCancel = (project) => {
    console.log(project);
    setProjectNameToCancel(project.title);
    setProjectTokenToCancel(project.projectToken);
    setProjectIdToCancel(project._id);
    setCancelModal(true);
  };

  let projectsToDisplay = (
    <div className="no-project-container">
      <p className="titre-card-type-2 no-mp">
        {t("no project for the moment.label")}
      </p>
    </div>
  );
  if (projectsData.length > 0) {
    projectsToDisplay = projectsData.map((project, i) => {
      let projectSrc = "/images/home-bene/projet3.png";
      if (project.category === "Environnement") {
        projectSrc = "/images/projectPic/environnemental.jpg";
      }
      if (project.category === "Solidarité") {
        projectSrc = "/images/projectPic/solidarity.jpg";
      }
      if (project.category === "Pauvreté") {
        projectSrc = "/images/projectPic/poverty.jpg";
      }
      return (
        <div key={i} className="card-dashboard" style={{ display: "flex" }}>
          <div>
            <img
              style={{ width: "300px" }}
              className="image-dashboard"
              src={projectSrc}
              alt="a generic background"
            />
          </div>
          <div className="dashboard-content">
            <p className="titre-card-type-2 no-mp">{project.title}</p>
            <p className="text-dashboard">{project.desc}</p>
          </div>
          <div>
            <span
              onClick={() => askForQuit(project)}
              style={{ cursor: "pointer" }}
              className="button-default"
            >
              {t("leave project.label")}
            </span>
          </div>
        </div>
      );
    });
  }

  let ApplicationToDisplay = (
    <div className="no-project-container">
      <p className="titre-card-type-2 no-mp">
        {t("no application in progress for the moment.label")}
      </p>
    </div>
  );
  if (applicationData.length > 0) {
    ApplicationToDisplay = applicationData.map((application, i) => {
      let projectSrc = "/images/home-bene/projet3.png";
      if (application.category === "Environnement") {
        projectSrc = "/images/projectPic/environnemental.jpg";
      }
      if (application.category === "Solidarité") {
        projectSrc = "/images/projectPic/solidarity.jpg";
      }
      if (application.category === "Pauvreté") {
        projectSrc = "/images/projectPic/poverty.jpg";
      }
      return (
        <div key={i} className="card-dashboard" style={{ display: "flex" }}>
          <div>
            <img
              style={{ width: "300px" }}
              className="image-dashboard"
              src={projectSrc}
              alt="a generic background"
            />
          </div>
          <div className="dashboard-content">
            <p className="titre-card-type-2 no-mp">{application.title}</p>
            <p className="text-dashboard">{application.desc}</p>
          </div>
          <div>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => askForCancel(application)}
              className="button-default"
            >
              {t("cancel my application.label")}
            </span>
          </div>
        </div>
      );
    });
  }

  if (!tokenId || userType !== "volunteer") {
    return <Navigate to="/" />;
  }
  return (
    <div className="full-width bg-profil">
      <HeaderConnected show={false} />
      <div
        className="full-width"
        style={{ position: "relative", bottom: "110px" }}
      >
        <>
          <Modal isOpen={quitModal} toggle={toggle}>
            <ModalBody>
              {t("quitproject.label")} {projectNameToQuit} ?
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => setQuitModal(false)}>
                {t("cancel.label")}
              </Button>
              <Button color="secondary" onClick={() => handleQuitProject()}>
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </>
        <>
          <Modal isOpen={cancelModal} toggle={toggleCancel}>
            <ModalBody>
              {t("quitproject.label")} {projectNameToCancel} ?
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => setCancelModal(false)}>
                {t("cancel.label")}
              </Button>
              <Button
                color="secondary"
                onClick={() => handleCancelApplication()}
              >
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </>
        <>
          <Modal isOpen={errorModal} toggle={toggleError}>
            <ModalBody>{errorMessage}</ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => window.location.reload()}
              >
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </>
        <h1 className="titre-h1-page">{t("dashboard.label")}</h1>

        <div>
          <Link
            to="/projectbank"
            className="button-default"
            style={{ marginLeft: "10%" }}
          >
            {t("findacause.label")}
          </Link>
        </div>

        <h2 className="titre-h2-page">{t("project in progress.label")} </h2>

        <div style={{ marginBottom: "40px" }}>{projectsToDisplay}</div>

        <h2 className="titre-h2-page">
          {t("application.label")}{" "}
          {applicationData.length > 0 ? (
            <div
              style={{ position: "relative", left: "300px", bottom: "60px" }}
              className="notification_pastilla"
            ></div>
          ) : null}{" "}
        </h2>

        <div style={{ marginBottom: "40px" }}>{ApplicationToDisplay}</div>

        <h2 className="titre-h2-page">{t("profil.label")} </h2>

        <div>
          <div className="card-dashboard">
            <div>
              <img
                className="image-dashboard-profil"
                src="/images/skill-bank/panda-bear.png"
                alt="a generic background"
              />
            </div>
            <div className="dashboard-content" style={{ alignItems: "center" }}>
              <p className="titre-card-type-2 no-mp">
                {profilData?.prenom} {profilData?.nom}
              </p>
            </div>
            <div>
              <Link to="/profilbenevole" className="button-default-small">
                {t("profil.label")}
              </Link>
            </div>
          </div>
          <div className="card-dashboard">
            <p className="text-dashboard-profil">{profilData?.desc}</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

function mapStateToProps(state) {
  return { userIdentification: state.token };
}

export default connect(mapStateToProps, null)(ScreenDashboardBenevoles);
