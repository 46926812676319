import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./App.css";
import { Link, Navigate } from "react-router-dom";
import HeaderConnected from "./components/HeaderConnected";
import Footer from "./components/Footer";
import { connect } from "react-redux";
import { Modal, Button, ModalBody, ModalFooter } from "reactstrap";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { fetchApi } from "./utils/api";

const ScreenGestionProjet = (props) => {
  const { t } = useTranslation();
  const { projectToken } = useParams();
  const [applicants, setApplicants] = useState([]);
  const [benevoles, setBenevoles] = useState([]);

  const [removeModal, setRemoveModal] = useState(false);
  const [setNameToRemove] = useState("");
  const [tokenToRemove, setTokenToRemove] = useState("");
  const [idToRemove, setIdToRemove] = useState("");

  const [refuseModal, setRefuseModal] = useState(false);
  const [applicantTokenToRefuse, setApplicantTokenToRefuse] = useState("");
  const [setApplicantNameToRefuse] = useState("");
  const [applicantIdToRefuse, setApplicantIdToRefuse] = useState("");

  const [acceptModal, setAcceptModal] = useState(false);
  const [applicantTokenToAccept, setApplicantTokenToAccept] = useState("");
  const [setApplicantNameToAccept] = useState("");
  const [applicantIdToAccept, setApplicantIdToAccept] = useState("");
  const [fullApplicantToAccept, setFullApplicantToAccept] = useState("");

  const [errorModal, setErrorModal] = useState(false);
  const [errorApplicantModal, setErrorApplicantModal] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const [cookies] = useCookies(["token"]);

  const token = props.userIdentification.token || cookies.token;
  const userType = props.userIdentification.userType || cookies.type;

  useEffect(() => {
    const loadProjectData = async () => {
      const response = await fetchApi(
        `/get_project_data?token=${projectToken}`,
        "GET"
      );

      setBenevoles(response.benevoles);
      setApplicants(response.applicants);
    };
    if (projectToken) {
      loadProjectData();
    }
  }, [projectToken]);

  const handleRefuseApply = async () => {
    setRefuseModal(false);
    const res = await fetchApi(
      "/refuseApply",
      "POST",
      `applicantToken=${applicantTokenToRefuse}&projectToken=${projectToken}`
    );

    if (res.result === true) {
      let applicantCopy = applicants.filter(
        (e) => e._id !== applicantIdToRefuse
      );
      setApplicants(applicantCopy);
    }
    if (res.result === false) {
      setErrorApplicantModal(true);
    }
  };
  const toggleError = () => setErrorModal(!errorModal);
  const toggleRefuse = () => setRefuseModal(!refuseModal);
  const toggle = () => setRemoveModal(!removeModal);
  const toggleErrorApplicant = () =>
    setErrorApplicantModal(!errorApplicantModal);
  const toggleAccept = () => setAcceptModal(!acceptModal);

  const handleRemove = async () => {
    setRemoveModal(false);

    const res = await fetchApi(
      "/remove-from-project",
      "POST",
      `volunteerToken=${tokenToRemove}&projectToken=${projectToken}`
    );

    if (res.result === true) {
      let volunteerCopy = benevoles.filter((e) => e._id !== idToRemove);
      setBenevoles(volunteerCopy);
    }
    if (res.result === false) {
      setErrorModal(true);
    }
  };

  const handleAcceptApply = async () => {
    setAcceptModal(false);

    const res = await fetchApi(
      "/validateApply",
      "POST",
      `applicantToken=${applicantTokenToAccept}&projectToken=${projectToken}`
    );

    if (res.result === true) {
      let applicantCopy = applicants.filter(
        (e) => e._id !== applicantIdToAccept
      );
      setApplicants(applicantCopy);
      setBenevoles([...benevoles, fullApplicantToAccept]);
    }
  };


  const deleteProject = async () => {
    fetchApi(`/delete-project?token=${token}&projectToken=${projectToken}`, "GET").then(() => {
      setRedirect(true);
    });
  }

  const askForRemove = (volunteer) => {
    setNameToRemove(volunteer.prenom);
    setTokenToRemove(volunteer.token);
    setIdToRemove(volunteer._id);
    setRemoveModal(true);
  };

  const askForRefuse = (applicant) => {
    setApplicantIdToRefuse(applicant._id);
    setApplicantNameToRefuse(applicant.prenom + applicant.nom);
    setApplicantTokenToRefuse(applicant.token);
    setRefuseModal(true);
  };

  const askForAccept = (candidat) => {
    console.log("accept", candidat);
    setApplicantIdToAccept(candidat._id);
    setApplicantNameToAccept(candidat.prenom + candidat.nom);
    setApplicantTokenToAccept(candidat.token);
    setFullApplicantToAccept(candidat);
    setAcceptModal(true);
  };

  let benevolesList = (
    <div style={{ marginBottom: "40px" }}>
      <div className="card-dashboard" style={{ display: "flex" }}>
        <p className="titre-card-type-2 no-mp">
          {t("no volunteer working this project.label")}{" "}
        </p>
      </div>
    </div>
  );

  if (benevoles.length > 0) {
    benevolesList = benevoles.map((benevole, i) => {
      return (
        <div style={{ marginBottom: "40px" }}>
          <div className="card-dashboard" style={{ display: "flex" }}>
            <div>
              <img
                className="image-dashboard-asso"
                src="/images/skill-bank/panda-bear.png"
                alt="a generic background"
              />
            </div>
            <div className="dashboard-content">
              <Link to={`/profil/${benevole.token}`} className="link">
                <p className="titre-card-type-2 no-mp">
                  {benevole.prenom} {benevole.nom}
                </p>
              </Link>
              <p className="text-dashboard">{benevole.desc}</p>
            </div>
            <div>
              <span
                onClick={() => askForRemove(benevole)}
                style={{ cursor: "pointer" }}
                className="button-default"
              >
                {t("remove from project.label")}
              </span>
            </div>
          </div>
        </div>
      );
    });
  }

  let candidatsList = (
    <div style={{ marginBottom: "40px" }}>
      <div className="card-dashboard" style={{ display: "flex" }}>
        <p className="titre-card-type-2 no-mp">
          {t("no applicant for this project.label")}{" "}
        </p>
      </div>
    </div>
  );
  if (applicants.length > 0) {
    candidatsList = applicants.map((candidat, i) => {
      return (
        <div style={{ marginBottom: "40px" }}>
          <div className="card-dashboard" style={{ display: "flex" }}>
            <div>
              <img
                className="image-dashboard-asso"
                src="/images/skill-bank/panda-bear.png"
                alt="a generic background"
              />
            </div>
            <div className="dashboard-content">
              <Link to={`/profil/${candidat.token}`} className="link">
                {" "}
                <p className="titre-card-type-2 no-mp">
                  {candidat.prenom}
                  {candidat.nom}
                </p>
              </Link>
              <p className="text-dashboard">{candidat.desc}</p>
            </div>
            <div>
              <span
                onClick={() =>
                  window.open(`mailto:${candidat.email}`, "_blank")
                }
                style={{ cursor: "pointer" }}
                className="button-default-small"
              >
                {t("contact.label")}
              </span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => askForAccept(candidat)}
                className="button-default-small"
              >
                {t("validate application.label")}
              </span>
              <span
                style={{ cursor: "pointer" }}
                onClick={() => askForRefuse(candidat)}
                className="button-default-small-red"
              >
                {t("cancel this application.label")}
              </span>
            </div>
          </div>
        </div>
      );
    });
  }

  if (redirect) {
    return <Navigate to="/dashboardoeuvre" />;
  }

  if (!token || userType !== "charity") {
    return <Navigate to="/" />;
  }
  return (
    <div className="full-width bg-profil">
      <HeaderConnected show={false} oeuvre />
      <div
        className="full-width"
        style={{ position: "relative", bottom: "110px" }}
      >
        <>
          <Modal isOpen={refuseModal} toggle={toggleRefuse}>
            <ModalBody>{t("reject application.label")}</ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => setRefuseModal(false)}>
                {t("cancel.label")}
              </Button>
              <Button color="secondary" onClick={() => handleRefuseApply()}>
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </>
        <>
          <Modal isOpen={acceptModal} toggle={toggleAccept}>
            <ModalBody>{t("acceptapplication.label")}</ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => setAcceptModal(false)}>
                {t("cancel.label")}
              </Button>
              <Button color="secondary" onClick={() => handleAcceptApply()}>
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </>
        <>
          <Modal isOpen={removeModal} toggle={toggle}>
            <ModalBody>{t("removehimfromproject.label")}</ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => setRemoveModal(false)}>
                {t("cancel.label")}
              </Button>
              <Button
                color="secondary"
                onClick={() => handleRemove(tokenToRemove)}
              >
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </>
        <>
          <Modal isOpen={errorModal} toggle={toggleError}>
            <ModalBody>Erreur, le candidat à déjà quitté le projet.</ModalBody>
            <ModalFooter>
              {/* <Button color="primary" onClick={()=>setAcceptModal(false)}>Annuler</Button> */}
              <Button
                color="secondary"
                onClick={() => window.location.reload()}
              >
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </>
        <>
          <Modal isOpen={errorApplicantModal} toggle={toggleErrorApplicant}>
            <ModalBody>
              Erreur, le candidat avait annulé sa candidature.
            </ModalBody>
            <ModalFooter>
              {/* <Button color="primary" onClick={()=>setAcceptModal(false)}>Annuler</Button> */}
              <Button
                color="secondary"
                onClick={() => window.location.reload()}
              >
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </>
        <h1 className="titre-h1-page">{t("manage my project.label")}</h1>
        <div style={{ marginBottom: "5%" }}>
          <Link
            to="/skillbank"
            className="button-default"
            style={{ marginLeft: "10%" }}
          >
            {t("search volunteer.label")}{" "}
          </Link>
        </div>
        <div>
          <p> </p>
          <p> </p>
          <p> </p>
        </div>
        <div>
          <Link
            to="/projetassodetail"
            className="button-default"
            style={{ marginLeft: "10%" }}
          >
            {t("overview project.label")}{" "}
          </Link>
          <Link
            to={`/editproject/${projectToken}`}
            className="button-default"
            style={{ marginLeft: "10%" }}
          >
            {t("Edit Project.label")}
          </Link>
        </div>
        <div style={{marginTop: 40}}>
          <button
            onClick={deleteProject}
            className="button-default"
            style={{ marginLeft: "10%", border: "none" }}
          >
            {t("delete project.label")}{" "}
          </button>
        </div>
        <h2 className="titre-h2-page">{t("my volunteer.label")} </h2>

        {benevolesList}

        <h2 className="titre-h2-page">{t("my applicants.label")}</h2>

        {candidatsList}
      </div>
      <Footer />
    </div>
  );
};

function mapStateToProps(state) {
  return { userIdentification: state.token };
}

export default connect(mapStateToProps, null)(ScreenGestionProjet);
