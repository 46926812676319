import React, { useState, useEffect } from "react";
import {
  Label,
  Input,
  Modal,
  Button,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import "./App.css";
import { Navigate, useSearchParams } from "react-router-dom";
import Footer from "./components/Footer";
import { connect } from "react-redux";
import Select from "react-select";
import HeaderConnected from "./components/HeaderConnected";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { fetchApi } from "./utils/api";

const ScreenInscriptionBenevolesFormulaire = (props) => {
  const { t, i18n } = useTranslation();

  const selectCompetence = [
    {
      value: "Agriculture",
      label: t("Agriculture.label"),
    },
    {
      value: "Public speaking",
      label: t("Public speaking.label"),
    },
    {
      value: "Biology",
      label: t("Biology.label"),
    },
    {
      value: "Communication",
      label: t("Communication.label"),
    },
    {
      value: "Construction",
      label: t("Construction.label"),
    },
    {
      value: "UX design",
      label: t("UX design.label"),
    },
    {
      value: "Consulting",
      label: t("Consulting.label"),
    },
    {
      value: "Cloud Computing",
      label: t("Cloud Computing.label"),
    },
    {
      value: "Writing",
      label: t("Writing.label"),
    },
    {
      value: "Education",
      label: t("Education.label"),
    },
    {
      value: "Electrician",
      label: t("Electrician.label"),
    },
    {
      value: "Electronic",
      label: t("Electronic.label"),
    },
    {
      value: "Event",
      label: t("Event.label"),
    },
    {
      value: "Finance",
      label: t("Finance.label"),
    },
    {
      value: "AI (Artificial Intelligence)",
      label: t("AI (Artificial Intelligence).label"),
    },
    {
      value: "Nurse",
      label: t("Nurse.label"),
    },
    {
      value: "Computing",
      label: t("Computing.label"),
    },
    {
      value: "Legal",
      label: t("Legal.label"),
    },
    {
      value: "Masonry",
      label: t("Masonry.label"),
    },
    {
      value: "Marketing",
      label: t("Marketing.label"),
    },
    {
      value: "Mechanic",
      label: t("Mechanic.label"),
    },
    {
      value: "Doctor",
      label: t("Doctor.label"),
    },
    {
      value: "Carpenter",
      label: t("Carpenter.label"),
    },
    {
      value: "Musician",
      label: t("Musician.label"),
    },
    {
      value: "Oceanology",
      label: t("Oceanology.label"),
    },
    {
      value: "Plumbery",
      label: t("Plumbery.label"),
    },
    {
      value: "Video production",
      label: t("Video production.label"),
    },
    {
      value: "School teacher",
      label: t("School teacher.label"),
    },
    {
      value: "Press relation",
      label: t("Press relation.label"),
    },
    {
      value: "Repair",
      label: t("Repair.label"),
    },
    {
      value: "Social Media",
      label: t("Social Media.label"),
    },
    {
      value: "Translation",
      label: t("Translation.label"),
    },
    {
      value: "UX Design",
      label: t("UX Design.label"),
    },
    {
      value: "Veterinarian",
      label: t("Veterinarian.label"),
    },
    {
      value: "Web Analytic",
      label: t("Web Analytic.label"),
    },
    {
      value: "Web design",
      label: t("Web design.label"),
    },
    {
      value: "Web development",
      label: t("Web development.label"),
    },
    {
      value: "Other",
      label: t("Other.label"),
    },
  ];

  const selectVisibilite = [
    {
      value: "Public",
      label: `${t("public.label")}`,
    },
    {
      value: "Limité",
      label: `${t("limited.label")}`,
    },
    {
      value: "Privé",
      label: `${t("private.label")}`,
    },
  ];

  const selectDispo = [
    {
      value: "À distance",
      label: `${t("remote.label")}`,
    },
    {
      value: "Locale",
      label: `${t("face-to-face.label")}`,
    },
    {
      value: "Les Deux",
      label: `${t("both.label")}`,
    },
  ];

  const [signUpName, setSignUpName] = useState("");
  const [signUpFirstName, setSignUpFirstName] = useState("");
  const [signUpVille, setSignUpVille] = useState("");
  const [signUpSkill, setSignUpSkill] = useState([]);
  const [signUpExpPro, setSignUpExpPro] = useState("");
  const [signUpDispo, setSignUpDispo] = useState(null);
  const [signUpVisibility, setSignUpVisibility] = useState(null);
  const [signUpDesc, setSignUpDesc] = useState("");
  const [signUpConvictions, setSignUpConvictions] = useState("");
  const [signupCountry, setSignUpCountry] = useState("");
  const [loading, setLoading] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [modalSuccessMessage, setModalSuccessMessage] = useState("");
  const [modalErrorMessage, setModalErrorMessage] = useState("");

  const [formulaireOK, setFormulaireOK] = useState(false);
  const [cookies] = useCookies(["token"]);
  const [error, setError] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const token = props.userIdentification.token || cookies.token;
  const userType = props.userIdentification.userType || cookies.type;

  useEffect(() => {
    const loadProfilData = async () => {
      setLoading(true);
      const getToken = searchParams.get("callback");
      const res = await fetchApi("/getprofildata", "POST", `token=${getToken}`);

      if (getToken === null || res.status === "pending") {
        const getType = searchParams.get("type");
        const getUserId = searchParams.get("userId");
    
        if (getToken && getType && getUserId) {
        fetchApi(`/users/verify-account/${getUserId}/${getToken}/${getType}`)
          .then((res) => {
            setModalVisible(false);
          }).catch((err) => {
            console.log(err);
          });
        } else {
          setModalVisible(true);
        }
      }

      setSignUpFirstName(res.prenom);
      setSignUpName(res.nom);
      setSignUpExpPro(res.expPro);
      setSignUpVille(res.ville);
      setSignUpSkill(res.competences);
      setSignUpDesc(res.desc);
      setSignUpConvictions(res.convictions);
      setSignUpCountry(res.country);

      if (res.disponibilites) {
        setSignUpDispo({
          value: res.disponibilites,
          label: res.disponibilites,
        });
      }

      if (res.visibilite === "Public") {
        setSignUpVisibility({
          value: res.visibilite,
          label: `${t("public.label")}`,
        });
      }
      if (res.visibilite === "Limité") {
        setSignUpVisibility({
          value: res.visibilite,
          label: `${t("limited.label")}`,
        });
      }
      if (res.visibilite === "Privé") {
        setSignUpVisibility({
          value: res.visibilite,
          label: `${t("private.label")}`,
        });
      }
      setLoading(false);
    };
    // if (token) {
      loadProfilData();
    // }
  }, [token, t]);

  const handleActivationEmail = async () => {
    const res = await fetchApi(
      `/users/send-activation-email?token=${token}&locale=${i18n.language}`,
      "GET"
    );
    if (res.result === "success") {
      setModalSuccessMessage("Mail re-envoyé");
    }
    if (res.result === "error") {
      setModalErrorMessage("Échec lors de l'envoi du mail");
    }
  };

  var handleSubmitSignupForm = async () => {
    let upload = true;
    if (
      !signUpName ||
      !signUpSkill ||
      !signUpVisibility ||
      !signUpVille ||
      !signUpFirstName ||
      // !signUpExpPro ||
      !signUpDispo ||
      // !signUpDesc ||
      // !signUpConvictions ||
      !signupCountry
    ) {
      upload = false;
      setError("Veuillez remplir tous les champs");
    }

    if (upload) {
      const res = await fetchApi(
        "/users/volunteer-sign-up-form",
        "POST",
        `nameFromFront=${signUpName}&firstNameFromFront=${signUpFirstName}&villeFromFront=${signUpVille}&skillFromFront=${JSON.stringify(
          signUpSkill
        )}&expProFromFront=${signUpExpPro}&dispoFromFront=${
          signUpDispo.value
        }&visibiliteFromFront=${
          signUpVisibility.value
        }&descFromFront=${signUpDesc}&convictionsFromFront=${signUpConvictions}&token=${token}&country=${signupCountry}`
      );

      if (res.result === true) {
        setFormulaireOK(true);
      }
    }
  };

  if (formulaireOK) {
    return <Navigate to="/dashboardbenevoles" />;
  }

  const handleChange = (value) => {
    setSignUpSkill(value);
  };

  const handleChangeDispo = (value) => {
    setSignUpDispo(value);
  };

  const handleChangeVisible = (value) => {
    setSignUpVisibility(value);
  };
  const toggle = () => console.log("do nothing");
  // if (!token || userType !== "volunteer") {
  //   return <Navigate to="/" />;
  // }

  return (
    <div className="full-width bg-inscription-formulaire">
      <HeaderConnected show={false} />
      <div
        className="full-width"
        style={{ position: "relative", bottom: "110px" }}
      >
        <>
          <Modal isOpen={modalVisible} toggle={toggle}>
            <ModalBody>
              <div style={{ display: "flex", flexDirection: "column" }}>
                Veuillez cliquer sur le lien reçu par mail pour activer votre
                compte.
                <span style={{ color: "green" }}>
                  {modalSuccessMessage ? modalSuccessMessage : null}
                </span>
                <span style={{ color: "red" }}>
                  {modalErrorMessage ? modalErrorMessage : null}
                </span>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => handleActivationEmail()}>
                Renvoyer le lien
              </Button>
              <Button
                color="secondary"
                onClick={() => window.location.reload()}
              >
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </>
        <div>
          <h1 className="titre-h1-page">{t("registration.label")}</h1>
          <div className="full-width">
            <div className="formulaire-default">
              <Label for="Nom Benevole" className="formulaire-label">
                {t("name.label")}
              </Label>
              <Input
                value={signUpName}
                onChange={(e) => setSignUpName(e.target.value)}
                type="lastname"
                name="lastname"
                id="lastnameB"
                placeholder="Grandjean"
                bsSize="sm"
              />
            </div>

            <div className="formulaire-default">
              <Label for="Prenom Benevole" className="formulaire-label">
                {t("firstname.label")}
              </Label>
              <Input
                value={signUpFirstName}
                onChange={(e) => setSignUpFirstName(e.target.value)}
                type="firstname"
                name="firstname"
                id="firstnameB"
                placeholder="Jacques"
                bsSize="sm"
              />
            </div>

            <div className="formulaire-default">
              <Label for="ville" className="formulaire-label">
                {t("city.label")}
              </Label>
              <Input
                value={signUpVille}
                onChange={(e) => setSignUpVille(e.target.value)}
                type="city"
                name={t("city.label")}
                id="cityB"
                placeholder={t("city.label")}
                bsSize="sm"
              />
            </div>
            <div className="formulaire-default">
              <Label for="country" className="formulaire-label">
                {t("country.label")}
              </Label>
              <Input
                value={signupCountry}
                onChange={(e) => setSignUpCountry(e.target.value)}
                type="country"
                name="country"
                id="cityB"
                placeholder="France"
                bsSize="sm"
              />
            </div>
            <div className="formulaire-default">
              <Label for="exampleSelectMulti" className="formulaire-label">
                {t("select_skills.label")}
              </Label>
              <Select
                placeholder={t("Please select your skills.label")}
                value={signUpSkill}
                options={selectCompetence}
                onChange={handleChange}
                isMulti
              />
            </div>

            <div className="formulaire-default">
              <Label for="exampleText" className="formulaire-label facultatif">
                {t("Professional Experience.label")}
              </Label>
              <Input
                value={signUpExpPro}
                onChange={(e) => setSignUpExpPro(e.target.value)}
                type="textarea"
                name="text"
                id="experience-pro"
                placeholder={t(
                  "Please list your previous experiences related to your skills"
                )}
                bsSize="sm"
              />
            </div>

            <div className="formulaire-default">
              <Label for="exampleSelect" className="formulaire-label">
                {t("Your availability.label")}
              </Label>
              {loading ? null : (
                <Select
                  //value={setSignUpDispo}
                  defaultValue={signUpDispo}
                  placeholder={t(
                    "Please choose your mode of participation.label"
                  )}
                  options={selectDispo}
                  onChange={handleChangeDispo}
                />
              )}
            </div>

            <div className="formulaire-default">
              <Label for="exampleSelect" className="formulaire-label">
                {t("Your visibility.label")}
              </Label>
              {loading ? null : (
                <Select
                  defaultValue={signUpVisibility}
                  placeholder="Veuillez-choisir la visibilité de votre profil"
                  options={selectVisibilite}
                  onChange={handleChangeVisible}
                />
              )}
            </div>

            <div className="formulaire-default">
              <Label for="exampleText" className="formulaire-label facultatif">
                {t("your description.label")}
              </Label>
              <Input
                value={signUpDesc}
                onChange={(e) => setSignUpDesc(e.target.value)}
                type="textarea"
                name="text"
                id="experience-pro"
                placeholder={t("Please write a few words about yourself.label")}
                bsSize="sm"
              />
            </div>

            <div className="formulaire-default">
              <Label for="exampleText" className="formulaire-label facultatif">
                {t("your convictions.label")}{" "}
              </Label>
              <Input
                value={signUpConvictions}
                onChange={(e) => setSignUpConvictions(e.target.value)}
                type="textarea"
                name="text"
                id="experience-pro"
                placeholder={t(
                  "Please write a few words about your beliefs.label"
                )}
                bsSize="sm"
              />
            </div>

            {/* <div className='formulaire-default'>
                            <Label for="exampleFile" className='formulaire-label facultatif'>Télécharger votre CV (facultatif)</Label>
                            <Input onChange={(e) => setSignUpCV(e.target.files[0])} type="file" name="file" id="exampleFile" placeholder= "Veuillez-télécharger votre CV" />
                            <FormText color="muted">
                            Veuillez-télécharger votre CV
                            </FormText>
                        </div> */}

            {/* <div className='formulaire-default'>
                            <Label for="exampleText" className='formulaire-label facultatif'>Image de profil</Label>
                            <Input onChange={(e) => setSignUpImage(e.target.value)} type="file" placeholder="Veuillez " bsSize="sm"/>
                        </div> */}
          </div>
          {error ? (
            <p style={{ marginLeft: 100, color: "red" }}>{error}</p>
          ) : null}
          <Button
            onClick={() => handleSubmitSignupForm()}
            className="button-default-small"
            style={{ marginLeft: "10%", marginTop: "40px !important" }}
          >
            {t("confirm.label")}
          </Button>
        </div>
        <Footer />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return { userIdentification: state.token };
}

export default connect(
  mapStateToProps,
  null
)(ScreenInscriptionBenevolesFormulaire);
