import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import { Link, Navigate } from "react-router-dom";
import Benevoles from "./components/Benevoles";
import CardBenevoles from "./components/CardBenevoles";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { fetchApi } from "./utils/api";

function ScreenHomeBenevoles() {
  const { t } = useTranslation();
  const [projectList, setProjectList] = useState([]);
  const [cookies] = useCookies(["token"]);

  useEffect(() => {
    const findProjects = async () => {
      const res = await fetchApi(`/preview-project-bank-bene`, "GET");
      setProjectList(res.projectList);
    };

    findProjects();
  }, []);

  let projectBankList = projectList.map((project, i) => {
    return (
      <CardBenevoles
        title={project.title}
        desc={project.desc}
        skills={project.competence}
      />
    );
  });

  if (cookies.token && cookies.type === "volunteer") {
    return <Navigate to="/dashboardbenevoles" />;
  }
  if (cookies.token && cookies.type === "charity") {
    return <Navigate to="/dashboardoeuvre" />;
  }

  return (
    <div>
      <div className="full-width">
        <Header />
        <Benevoles />
        <div
          style={{ marginTop: "50px", marginRight: "10%", marginLeft: "10%" }}
        >
          <p className="normal-text-home-oeuvre">
            {" "}
            {t("You have convictions.label")}{" "}
          </p>
        </div>
        <Row>
          <Col xs="12" style={{ display: "flex", justifyContent: "center" }}>
            <h2 className="titre-h2">
              {t("projects_in_search_of_skills.label")}
            </h2>
          </Col>
        </Row>

        <Row className="card-project">{projectBankList}</Row>

        <Col xs="12" style={{ display: "flex", justifyContent: "center" }}>
          <h3 className="titre-autre">
            {t("more about current projects.label")}
          </h3>
        </Col>
      </div>

      <div style={{ display: "flex", justifyContent: "center", margin: "30px 0" }}>
        <Link to="/inscriptionbenevoles" className="button-default">
          {t("registration.label")}
        </Link>
        <Link to="/connectionbenevoles" className="button-default-purple">
          {t("connection.label")}
        </Link>
      </div>

      <Footer />
    </div>
  );
}

export default ScreenHomeBenevoles;
