import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";

import { Link, Navigate } from "react-router-dom";
import Charite from "./components/Charite";
import CardCharite from "./components/CardCharite";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { fetchApi } from "./utils/api";

function ScreenHomeBenevoles() {
  const { t } = useTranslation();
  const [skillList, setSkillList] = useState([]);
  const [cookies] = useCookies(["token"]);
  const isLimited = true;

  useEffect(() => {
    const findSkills = async () => {
      const response = await fetchApi(
        `/preview-benevoles-bank/${isLimited}`,
        "GET"
      );

      console.log(response.benevoleList);
      setSkillList(response.benevoleList);
    };

    findSkills();
  }, [isLimited]);

  let skillBankList = skillList.map((user, i) => {
    return (
      <CardCharite
        nom={user.nom}
        prenom={user.prenom}
        desc={user.desc}
        skills={user.competences}
        user={user}
        home
      />
    );
  });

  if (cookies.token && cookies.type === "volunteer") {
    return <Navigate to="/dashboardbenevoles" />;
  }
  if (cookies.token && cookies.type === "charity") {
    return <Navigate to="/dashboardoeuvre" />;
  }

  return (
    <div className="App">
      <div className="full-width">
        <Header />
        <Charite />
        <div
          style={{ marginTop: "50px", marginRight: "10%", marginLeft: "10%" }}
        >
          <p className="normal-text-home-oeuvre">
            {t("projects have a hard time getting started.label")}
          </p>
        </div>
        <Row>
          <Col xs="12" style={{ display: "flex", justifyContent: "center" }}>
            <h2 className="titre-h2-bis">
              {t("These people offer their skills.label")}
            </h2>
          </Col>
        </Row>
        <div className="bg-home-asso">
          <Row className="card-competence">{skillBankList}</Row>
        </div>
        <Row>
          <Col xs="12" style={{ display: "flex", justifyContent: "center" }}>
            <h3 className="titre-autre">
              {t("If you want to know more about skills.label")}
            </h3>
          </Col>
          <Col xs="12" style={{ display: "flex", justifyContent: "center", margin: "30px 0" }}>
            <Link to="/inscriptionbenevoles" className="button-default">
              {t("registration.label")}
            </Link>
            <Link
              to="/connectionbenevoles"
              className="button-default-purple"
            >
              {t("connection.label")}
            </Link>
          </Col>
        </Row>
        <Footer />
      </div>
    </div>
  );
}

export default ScreenHomeBenevoles;
